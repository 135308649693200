<div class="container-fluid">
    <form [formGroup]="detailform"  (ngSubmit)="onSubmit()">
            <div class="row">
                    <div class="col-md-12">
                            <div class="alert alert-danger alert-dismissible" *ngIf="isErrorAlertAPICall">
                                <a href="javascript:void(0);" class="close" (click)="onClickMsg('E')" aria-label="close">&times;</a>
                                <strong>Failure! </strong> {{ErrorMessageAPICall}}
                            </div>
                    </div>
                    </div>
        <div class="card">
            <div class="card-header font-weight-bold text-white" style="background-color:#5D87A1;">Anesthesia Quality Evaluation</div>
            <div class="card-body" >
                <div class="form-row pl-1 align-items-center">
                    <label class="text-muted"> Exclusions for MIPS reporting: labor epidurals, post-op pain management, and ancillary procedures such as epidural blood patch, IV placement, emergency intubation and ACLS.</label>
                </div>
                <div class="form-row pb-2 align-items-center">
                    <div class=" col-xs-3 col-sm-3 col-md-3 col-lg-3 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                        <label for="rdMacraApplicableYes" class="form-check-label">MACRA Form Applicable</label>
                    </div>
                    <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" value="1" formControlName="MAC700"  name="MAC700" class="form-check-input" id="rdMacraApplicableYes" (click)="toggleMACRAForm($event)" >
                                Yes
                            </label>
                        </div>
                    </div>
                    <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1  pb-0 pb-sm-0 pb-md-0 pb-lg-0">
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" value="0" formControlName="MAC700"  name="MAC700" class="form-check-input" id="rdMacraApplicableNo" (click)="toggleMACRAForm($event)">
                                No
                            </label>
                        </div>
                    </div>
                    <div class="col-xs-7 col-sm-5 col-md-7 col-lg-7"></div>
                </div>
                <div *ngIf="!isANESQualitySectionDisplay">
                        <div class="form-row pb-2 align-items-center ">
                            <div class="col-sm-3 pb-1 pb-sm-0 pb-md-0 pb-lg-0">
                                <label class="form-check-label" for="drpAnesthesiaTypeDoc" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC701'].errors?.required===true}">Anesthesia Type</label>
                            </div>
                            <div class="col-sm-4 pb-0 pb-sm-0 pb-md-0 pb-lg-0">
                                <select formControlName="MAC701" class="form-control" [ngClass]="{'border-danger': isFormSubmitted && detailform.controls['MAC701'].errors?.required===true}" (change)="onChangeAnesType()"  id="drpAnesthesiaTypeDoc">
                                    <option value="">--Select--</option>
                                    <option *ngFor="let val of this.AnesTypeDocList" value={{val.groupAttributeSubItemId}}>
                                            {{val.groupAttributeSubItem}}
                                    </option>
                                </select> 
                            </div>
                            <div class="col-sm-5"></div>
                        </div>
                        <div class="form-row pb-2 align-items-center">
                            <div class="col-sm-3  col-md-3 col-lg-3 col-xs-3 pb-1 pb-sm-0 pb-md-0 pb-lg-0">
                                <label class="form-check-label" for="drpASAStatusDoc" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC702'].errors?.required===true}">ASA Status</label>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4 col-xs-4 pb-1 pb-sm-0 pb-md-0 pb-lg-0">
                                <select formControlName="MAC702"  class="form-control" [ngClass]="{'border-danger': isFormSubmitted && detailform.controls['MAC702'].errors?.required===true}" id="drpASAStatusDoc" (change)="onChangeASA($event)">
                                    <option value="">--Select--</option>
                                    <option *ngFor="let val of this.ASAStatus" value={{val.groupAttributeSubItemId}}>
                                        {{val.groupAttributeSubItem}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-2 col-md-1 col-lg-1 col-xs-1 col-lg-1 pb-0 pb-md-0 pb-lg-0 pb-sm-0 ">
                                <label for="rdEmergencyYes"   [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC703'].errors?.required===true}">Emergency</label> 
                            </div>
                            <div class="col-sm-2 col-md-1 col-lg-1 col-xs-1 col-lg-1 pb-2 pb-md-0 pb-lg-0 pb-sm-0 pl-sm-3 pl-md-5">
                                <div class="form-check">
                                    <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC703'].errors?.required===true}">
                                        <input type="radio" formControlName="MAC703" value="1" class="form-check-input"  id="rdEmergencyYes" name="MAC703" (click)="ClickMAC703($event)" >
                                        Yes
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 pb-0 pb-md-0 pb-lg-0 pb-sm-0 pl-md-5">
                                <div class="form-check">
                                    <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC703'].errors?.required===true}">
                                        <input type="radio"  formControlName="MAC703" class="form-check-input"   value="0" id="rdEmergencyNo" name="MAC703" (click)="ClickMAC703($event)" >
                                        No
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-0 col-md-2 col-lg-2 col-xs-2"></div>
                        </div>                        
                        <div class="form-row pb-2 align-items-center">
                            <div class=" col-xs-3 col-sm-3 col-md-3 col-lg-3 pb-0 pb-sm-0 pb-md-0 pb-lg-0">
                                <label for="rdMacraApplicableYes" class="form-check-label">Age</label>
                            </div>
                            <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div *ngFor="let item of AgeList;" >
                                    <div *ngIf="item.groupAttributeSubItemId==='MAC709A'">
                                        <div class="form-check">
                                            <label class="form-check-label" for="MAC709A" >
                                                <input type="radio" value="1" formControlName="MAC709A"  name="MAC709A" class="form-check-input" id="MAC709A" (click)="AgeLoadMeasures('MAC709A','I')" >
                                                {{item.groupAttributeSubItem}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-2 col-md-1 col-lg-1 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div *ngFor="let item of AgeList;" >
                                    <div *ngIf="item.groupAttributeSubItemId==='MAC709B'">
                                        <div class="form-check">
                                            <label class="form-check-label" for="MAC709B">
                                                <input type="radio" value="1" formControlName="MAC709B"  name="MAC709B" class="form-check-input" id="MAC709B" (click)="AgeLoadMeasures('MAC709B','I')">
                                                 {{item.groupAttributeSubItem}}                                         
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div *ngFor="let item of AgeList;">
                                    <div *ngIf="item.groupAttributeSubItemId==='MAC709C'">
                                        <div class="form-check">
                                            <label class="form-check-label" for="MAC709C">
                                                <input type="radio" value="1" formControlName="MAC709C"  name="MAC709C" class="form-check-input" id="MAC709C" (click)="AgeLoadMeasures('MAC709C','I')">
                                                {{item.groupAttributeSubItem}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-5 col-sm-6 col-md-6 col-lg-6"></div>
                            <!-- <div class="col-sm-0 col-md-0 col-lg-0 col-xs-0"></div> -->
                        </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isDisplay">
            <div class="card" >
                <div class="card-header font-weight-bold" style="background-color:#D5DCE6">MIPS 44 - Perioperative Beta Blocker in patients with isolated CABG Surgery (18 & older)</div>
                <div class="card-body">
                <div *ngFor="let item of MIPS44List;"> 
                    <div *ngIf="item.groupAttributeId==='MAC216'" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                         <div class="form-check ">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrors===true}" for="chkPreoperativeNA">
                                <input type="radio" value="1" class="form-check-input"   id="chkPreoperativeNA" name="MAC216" formControlName="MAC216" (change)="onChangeMIPS44('MAC216')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC217'" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrors===true}" for="chkBetaSugicalIncision">
                                <input type="radio" value="1" class="form-check-input"   id="chkBetaSugicalIncision" name="MAC217" formControlName="MAC217" (change)="onChangeMIPS44('MAC217')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC218'" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0  align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrors===true}" for="chkBetaSugicalIncisiNotSpecified">
                                <input type="radio"  value="1" class="form-check-input"    id="chkBetaSugicalIncisiNotSpecified" name="MAC218" formControlName="MAC218" (change)="onChangeMIPS44('MAC218')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div> 
                    <div  *ngIf="item.groupAttributeId==='MAC219'" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0   align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrors===true}" for="chkMedicalSugicalIncision">
                                <input type="radio" value="1" class="form-check-input"  id="chkMedicalSugicalIncision" name="MAC219" formControlName="MAC219" (change)="onChangeMIPS44('MAC219')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                </div>
            
            
            </div>
            </div>
            <div class="card" >
                <div class="card-header font-weight-bold" style="background-color:#D5DCE6">MIPS 76 - Prevention of Central Venous Catheter (CVC) Related Bloodstream Infections (all patients)</div>
                <div class="card-body">
                    <div *ngFor="let item of MIPS76List"> 
                    <div *ngIf="item.groupAttributeId==='MAC024'" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0align-items-center">
                        <div  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0  ">
                            <div class=" form-check">
                                <label class="form-check-label" for="chkMIPS76NA">
                                    <input formControlName="MAC024" value="1" class="form-check-input" type="checkbox"  id="chkMIPS76NA" name="MAC024" (ngModelChange)="onChangeMIPS76NA($event)" >
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC022'" [ngClass]="{'text-muted': MIPS76Disabled}"  class="form-row pb-1 align-items-end">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0">
                            <div class="form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC022'].errors?.required===true}">
                                        {{item.groupAttributeName}}</label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class="form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC022'].errors?.required===true}" for="rdCentrallineYes">
                                    <input type="radio" formControlName="MAC022" value="1" class="form-check-input"  id="rdCentrallineYes" name="MAC022" (change)="onChangeCentralLine($event)">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pl-sm-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC022'].errors?.required===true}" for="rdCentrallineNo">
                                    <input type="radio"  formControlName="MAC022" class="form-check-input"   value="0" id="rdCentrallineNo" name="MAC022" (change)="onChangeCentralLine($event)">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6">
                            
                        </div>
                    </div>
                    <div  *ngIf="item.groupAttributeId==='MAC022A'" [ngClass]="{'text-muted': (MIPS76Disabled || MAC022ADisabled )}"  class="form-row pb-1 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsCentral===true}" for="chkmaximalsterile">
                                <input formControlName="MAC022A" class="form-check-input" type="radio" value="1" id="chkmaximalsterile" name="MAC022A" (change)="onChangeCentralLineSub($event,'MAC022A')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC022C'" [ngClass]="{'text-muted': (MIPS76Disabled || MAC022CDisabled)}"  class="form-row pb-1 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsCentral===true}" for="chkmaximalsterileNo">
                                <input formControlName="MAC022C" class="form-check-input" type="radio" value="1" id="chkmaximalsterileNo" name="MAC022C" (change)="onChangeCentralLineSub($event,'MAC022C')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC022B'" [ngClass]="{'text-muted':(MIPS76Disabled || MAC022BDisabled)}"  class="form-row pb-1 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsCentral=== true}" for="chkSterile">
                                <input formControlName="MAC022B"  class="form-check-input" type="radio" value="1" id="chkSterile" name="MAC022B" (change)="onChangeCentralLineSub($event,'MAC022B')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="card" >
                <div class="card-header font-weight-bold" style="background-color:#D5DCE6">MIPS 424 - Perioperative Temperature Management (all patients)</div>
                <div class="card-body ">
                  <div *ngFor="let item of MIPS424List">     
                    <div *ngIf="item.groupAttributeId==='MAC035'" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center mb-0">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0">
                            <div class=" form-check">
                                <label class="form-check-label" for="chkMIPS424NA">
                                    <input formControlName="MAC035" class="form-check-input" type="checkbox"  id="chkMIPS424NA" name="MAC035" (ngModelChange)="onChangeMIPS424NA($event)" >
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="item.groupAttributeId==='MAC032'" [ngClass]="{'text-muted': MIPS242Disabled}" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0">
                            <div class=" form-check">
                                <label class="form-check-label" class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC032'].errors?.required===true}">
                                        {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC032'].errors?.required===true}" for="rdAnesthesia60MinYes">
                                    <input formControlName="MAC032" class="form-check-input" type="radio" value="1" id="rdAnesthesia60MinYes" name="MAC032" (change)="onChangeAnesthesiaCase60($event)">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC032'].errors?.required===true}" for="rdAnesthesia60MinNo">
                                    <input formControlName="MAC032" class="form-check-input" type="radio" value="0" id="rdAnesthesia60MinNo" name="MAC032" (change)="onChangeAnesthesiaCase60($event)">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC032A'" [ngClass]="{'text-muted': (MIPS242Disabled || MAC032ADisabled)}" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAnesCase60===true}" for="chkDocumentWithin30Min">
                                <input  formControlName="MAC032A" class="form-check-input" type="radio" value="1"  id="chkDocumentWithin30Min" name="MAC032A" (change)="onChangeAnesthesiaCase60Sub($event,'MAC032A')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC032B'" [ngClass]="{'text-muted': (MIPS242Disabled || MAC032BDisabled)}" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAnesCase60===true}" for="chkMedicalReson1Body">
                                <input  formControlName="MAC032B" class="form-check-input" type="radio" value="1"  id="chkMedicalReson1Body" name="MAC032B" (change)="onChangeAnesthesiaCase60Sub($event,'MAC032B')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                    <div  *ngIf="item.groupAttributeId==='MAC032C'" [ngClass]="{'text-muted': (MIPS242Disabled || MAC032CDisabled)}" class="form-row pb-1 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAnesCase60===true}" for="chkBodyTemp">
                                <input formControlName="MAC032C"  class="form-check-input"  type="radio" value="1" id="chkBodyTemp" name="MAC032C" (change)="onChangeAnesthesiaCase60Sub($event,'MAC032C')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="card" >
                <div class="card-header font-weight-bold" style="background-color:#D5DCE6">MIPS 404 - Anesthesiology Smoking Abstinence (18 & Older)</div>
                <div class="card-body">
                   
                <div *ngFor="let item of MIPS404List">  
                    <div *ngIf="item.groupAttributeId==='MAC017'" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center mb-0">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0">
                            <div class=" form-check">
                                <label class="form-check-label" for="chkMIPS404NA">
                                    <input formControlName="MAC017" class="form-check-input" type="checkbox" value="1"  id="chkMIPS404NA" name="MAC017" (ngModelChange)="onChangeMIPS404NA($event)" >
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC014'"  [ngClass]="{'text-muted': MIPS404Disabled}" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC014'].errors?.required ===true}">{{item.groupAttributeName}}</label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC014'].errors?.required ===true}" for="rdPtCurrentSmokerYes">
                                    <input formControlName="MAC014" class="form-check-input" type="radio" value="1" id="rdPtCurrentSmokerYes" name="MAC014" (change)="onChangeCurrentSmoker($event)">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-0 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC014'].errors?.required ===true}" for="rdPtCurrentSmokerNo">
                                    <input  formControlName="MAC014" class="form-check-input"  type="radio" value="0" id="rdPtCurrentSmokerNo" name="MAC014" (change)="onChangeCurrentSmoker($event)">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                    </div>
                     
                    <div *ngIf="item.groupAttributeId==='MAC015'" [ngClass]="{'text-muted': (MIPS404Disabled || MAC015Disabled)}"  class="form-row pb-1 align-items-center">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorElectiveCase===true}">
                                        {{item.groupAttributeName}}</label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorElectiveCase===true}" for="rdPtReceivSmokingYes">
                                    <input formControlName="MAC015" class="form-check-input" type="radio" value="1" id="rdPtReceivSmokingYes" name="MAC015" (change)="onChangePriorInstruction($event)">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorElectiveCase===true}" for="rdPtReceivSmokingNo">
                                    <input formControlName="MAC015" class="form-check-input" type="radio" value="0" id="rdPtReceivSmokingNo" name="MAC015" (change)="onChangePriorInstruction($event)">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC015A'" [ngClass]="{'text-muted': (MIPS404Disabled || MAC015ADisabled)}"  class="form-row pb-1 align-items-center">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPtabstained===true}">
                                        {{item.groupAttributeName}}</label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPtabstained===true}" for="rdabstainedsmokeYes">
                                    <input formControlName="MAC015A" class="form-check-input" type="radio" value="1" id="rdabstainedsmokeYes" name="MAC015A" (change)="onChangePtabstainedsmoking($event)">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPtabstained===true}" for="rdabstainedsmokeNo">
                                    <input formControlName="MAC015A" class="form-check-input" type="radio" value="0" id="rdabstainedsmokeNo" name="MAC015A" (change)="onChangePtabstainedsmoking($event)">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC015B'" [ngClass]="{'text-muted': (MIPS404Disabled || MAC015BDisabled)}"  class="form-row pb-1 align-items-center">
                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div class=" form-check">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPtabstainedDOS===true}">
                                            {{item.groupAttributeName}}</label>
                                </div>
                            </div>
                            <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div class=" form-check">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPtabstainedDOS===true}" for="rdabstainedsmokeYesMAC015B">
                                        <input formControlName="MAC015B" class="form-check-input" type="radio" value="1" id="rdabstainedsmokeYesMAC015B" name="MAC015B" (change)="onChangePtabstainedsmokingMAC015B($event)">
                                        Yes
                                    </label>
                                </div>
                            </div>
                            <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0">
                                <div class=" form-check">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPtabstainedDOS===true}" for="rdabstainedsmokeNoMAC015B">
                                        <input formControlName="MAC015B" class="form-check-input" type="radio" value="0" id="rdabstainedsmokeNoMAC015B" name="MAC015B" (change)="onChangePtabstainedsmokingMAC015B($event)">
                                        No
                                    </label>
                                </div>
                            </div>
                            <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                        </div>
                </div>
            </div>
            </div>
            <div class="card" >
                <div class="card-header font-weight-bold" style="background-color:#D5DCE6">AQI xx - Intraoperative Antibiotic Redosing (18 & older)</div>
                <div class="card-body">
                        
                        <div *ngFor="let item of AQIList;">
                        <div *ngIf="item.groupAttributeId==='MAC312'" class="form-row align-items-center">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0">
                                <div class=" form-check">
                                    <label class="form-check-label" for="chkMIPSAQINA">
                                        <input formControlName="MAC312" class="form-check-input" type="checkbox"  id="chkMIPSAQINA" name="MAC312" (ngModelChange)="onChangeMIPSAQINA($event)" >
                                        {{item.groupAttributeName}}
                                    </label>
                                </div>
                            </div>
                        </div> 
                        <div *ngIf="item.groupAttributeId==='MAC301'"  [ngClass]="{'text-muted': AQIDisabled}"  class="form-row pb-1 align-items-center">
                                <div class="col-xs-4 col-sm-5 col-md-4 col-lg-4 pl-0">
                                    <div class=" form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQI===true}">
                                                Exclusions
                                        </label>
                                    </div>
                                </div>
                                <div  *ngIf="item.groupAttributeId==='MAC301'" class="col-xs-3 col-sm-4 col-md-3 col-lg-3 pl-0">
                                    <div class=" form-check">
                                         <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQI===true}" for="MAC301">
                                                <input formControlName="MAC301" class="form-check-input" type="checkbox" value="" id="MAC301" name="MAC301" (change)="onChangeAQI($event,'MAC301')" >
                                                {{item.groupAttributeName}}
                                            </label>
                                    </div>
                                </div>
                                <div class="col-xs-5 col-sm-3 col-md-5 col-lg-5"></div>
                                 </div>
     
                            <div class="form-row  align-items-center">
                                    <div class="col-xs-4 offset-xs-5 col-sm-4 offset-sm-5 col-md-4 offset-md-4 col-lg-4 offset-lg-4 pl-0">
                                    <div  *ngIf="item.groupAttributeId==='MAC302'"  [ngClass]="{'text-muted': AQIDisabled}" class="form-check">
                                        
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQI===true}" for="MAC302">
                                            <input formControlName="MAC302" class="form-check-input" type="checkbox" value="" id="MAC302" name="MAC302" (change)="onChangeAQI($event,'MAC302')" >
                                            {{item.groupAttributeName}}
                                        </label>
                                    </div>
                                    </div>
                                    <div class="col-xs-3 col-sm-3 col-md-4 col-lg-4"></div>
                                </div>
                            <div class="form-row pb-1 pb-sm-0 pb-md-0 pb-lg-0  align-items-center">
                                    <div class="col-xs-4 offset-xs-5 col-sm-4 offset-sm-5 col-md-4 offset-md-4 col-lg-6 offset-lg-4 pl-0">
                                        <div  *ngIf="item.groupAttributeId==='MAC303'"  [ngClass]="{'text-muted': AQIDisabled}"  class="form-check">
                                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQI===true}" for="MAC303">
                                                <input formControlName="MAC303" class="form-check-input" type="checkbox" value="" id="MAC303" name="MAC303" (change)="onChangeAQI($event,'MAC303')" >
                                                {{item.groupAttributeName}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-3 col-sm-3 col-md-4 col-lg-2 "></div>
                            </div>
                    <div  *ngIf="item.groupAttributeId==='MAC304'"  [ngClass]="{'text-muted': (AQIDisabled || MAC304Disabled) }" class="form-row align-items-center">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class="form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQI304===true}" >  {{item.groupAttributeName}}</label>
                            </div>
                           
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQI304===true}" for="rdNonEmergentYesMAC304">
                                    <input formControlName="MAC304" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC304" name="MAC304" (change)="onChangeProcedure($event)">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0">
                            <div class="form-check">
                                <label class="form-check-label"  [ngClass]="{'text-danger': hasErrorAQI304===true}" for="rdNonEmergentNoMAC304">
                                    <input formControlName="MAC304" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC304" name="MAC304" (change)="onChangeProcedure($event)">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC305'"  [ngClass]="{'text-muted': (AQIDisabled || MAC305Disabled)}" class="form-row align-items-center">
                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div class="form-check">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQIProcedure===true}"> {{item.groupAttributeName}}</label>
                                </div>
                            </div>
                            <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div class=" form-check">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQIProcedure===true}" for="rdNonEmergentYesMAC305">
                                        <input formControlName="MAC305" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC305" name="MAC305" (change)="onChangeAQIPtreceived($event)">
                                        Yes
                                    </label>
                                </div>
                            </div>
                            <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0">
                                <div class="form-check">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQIProcedure===true}" for="rdNonEmergentNoMAC305">
                                        <input formControlName="MAC305" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC305" name="MAC305" (change)="onChangeAQIPtreceived($event)">
                                        No
                                    </label>
                                </div>
                            </div>
                            <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                        </div>
                        <div *ngIf="item.groupAttributeId==='MAC305A'"  [ngClass]="{'text-muted': (AQIDisabled|| MAC305ADisabled)}" class="form-row align-items-center">
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQIPtreceived===true}"> {{item.groupAttributeName}}</label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQIPtreceived===true}" for="rdNonEmergentYesMACMAC305A">
                                            <input formControlName="MAC305A" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMACMAC305A" name="MAC305A" (change)="onChangeAQIantibiotic($event,'MAC305A')">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0">
                                    <div class="form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorAQIPtreceived===true}" for="rdNonEmergentNoMACMAC305A">
                                            <input formControlName="MAC305A" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMACMAC305A" name="MAC305A" (change)="onChangeAQIantibiotic($event,'MAC305A')">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC305B'"  [ngClass]="{'text-muted': (AQIDisabled|| MAC305BDisabled)}" class="form-row align-items-center">
                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorAQIPtreceived===true}"> {{item.groupAttributeName}}</label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorAQIPtreceived===true}" for="rdNonEmergentYesMAC305B">
                                                <input formControlName="MAC305B" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC305B" name="MAC305B" (change)="onChangeAQIantibiotic($event,'MAC305B')">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class="form-check">
                                            <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorAQIPtreceived===true}" for="rdNonEmergentNoMAC305B">
                                                <input formControlName="MAC305B" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC305B" name="MAC305B" (change)="onChangeAQIantibiotic($event,'MAC305B')">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                                </div>
                                <div *ngIf="item.groupAttributeId==='MAC309'" class="form-row align-items-left">
                                    <label class="text-muted"> {{item.groupAttributeName}}</label>
                                </div>
                        </div>   
                </div>
            </div>
            <div class="card" >
                <div class="card-header font-weight-bold" style="background-color:#D5DCE6">MIPS 430 - Prevention of PONV - Combination Therapy Adults (18 & older)</div>
                <div class="card-body ">
                        
                    <div class="form-row pb-1 align-items-center">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0">
                            <label class="text-muted"> <b>Risk factors:</b> 1) Female 2) History of PONV 3) History of motion sickness 4) Non-smoker 5) Intended use of opioids for post-op analgesia</label>
                        </div>
                    </div>
                    
                    <div *ngFor="let item of MIPS430List"> 

                    <div *ngIf="item.groupAttributeId==='MAC233'" class="form-row pb-1 align-items-center">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pb-1">
                            <div class=" form-check">
                                <label class="form-check-label" for="chkMIPS430NA">
                                    <input formControlName="MAC233" class="form-check-input" type="checkbox"  id="chkMIPS430NA" name="MAC233" (ngModelChange)="onChangeMIPS430NA($event)" >
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                    </div> 
                    <div  *ngIf="item.groupAttributeId==='MAC226'"  [ngClass]="{'text-muted': MIPS430Disabled}"  class="form-row pb-1 align-items-center">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC226'].errors?.required===true}">
                                        {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC226'].errors?.required===true}" for="rdPtRecInhalationYes">
                                    <input  formControlName="MAC226" class="form-check-input" type="radio" value="1" id="rdPtRecInhalationYes" name="MAC226" (change)="onChange430PtReceivedInhal($event)">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC226'].errors?.required===true}" for="rdPtRecInhalationNo">
                                    <input  formControlName="MAC226" class="form-check-input" type="radio" value="0" id="rdPtRecInhalationNo" name="MAC226" (change)="onChange430PtReceivedInhal($event)">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC227'"  [ngClass]="{'text-muted': (MIPS430Disabled || MAC227Disabled)}"  class="form-row pb-1 align-items-center">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientreceivedinhal===true}">
                                        {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientreceivedinhal===true}" for="rdPONVYes">
                                    <input  formControlName="MAC227" class="form-check-input" type="radio" value="1" id="rdPONVYes" name="MAC227" (change)="onChange430PtExhibitsPONV($event)">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientreceivedinhal===true}" for="rdPONVNo">
                                    <input formControlName="MAC227"  class="form-check-input" type="radio" value="0" id="rdPONVNo" name="MAC227" (change)="onChange430PtExhibitsPONV($event)">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC228'"  [ngClass]="{'text-muted':(MIPS430Disabled || MAC228Disabled)}"  class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits3 ===true}" for="chkMedReasTheraphy">
                                <input formControlName="MAC228" class="form-check-input" type="radio" value="1"  id="chkMedReasTheraphy" name="MAC228" (change)="onChange430PtExhibitsPONVSub($event,'MAC228')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC231'"  [ngClass]="{'text-muted': (MIPS430Disabled || MAC231Disabled)}"  class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits3 ===true}" for="chkMedReasTheraphy2">
                                <input  formControlName="MAC231" class="form-check-input" type="radio" value="1" id="chkMedReasTheraphy2" name="MAC231"  (change)="onChange430PtExhibitsPONVSub($event,'MAC231')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC229'"  [ngClass]="{'text-muted': (MIPS430Disabled || MAC229Disabled)}"  class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits3 ===true}" for="chkMedReasTheraphy3">
                                <input formControlName="MAC229" class="form-check-input" type="radio" value="1" id="chkMedReasTheraphy3" name="MAC229"  (change)="onChange430PtExhibitsPONVSub($event,'MAC229')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="card" >
                <div class="card-header font-weight-bold" style="background-color:#D5DCE6">MIPS 463 - Prevention of PONV - Combination Therapy Pediatrics (3-17 yrs old)</div>
                <div class="card-body">
                    <div class="form-row pb-1 align-items-center">
                        <label class="text-muted"><b>Risk factors:</b> 1) Surgery longer than 30 mins 2) Age > 3 y.o. 3) Strabismus surgery 4) History of POV or PONV in pt, parents or siblings</label>
                    </div>
                    <div *ngFor="let item of MIPS463List"> 
                    <div *ngIf="item.groupAttributeId==='MAC240'" class="form-row pb-1 align-items-center">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" for="chkMIPS463NA">
                                    <input formControlName="MAC240" class="form-check-input" type="checkbox"  id="chkMIPS463NA" name="MAC240" (ngModelChange)="onChangeMIPS463NA($event)" >
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                    </div> 
                    <div *ngIf="item.groupAttributeId==='MAC232'"   [ngClass]="{'text-muted': MIPS463Disabled}"  class="form-row pb-1 align-items-center">
                        <div class="col-xs-4 col-sm-5 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger':isFormSubmitted && !!detailform.controls['MAC232'].errors?.required===true}" >
                                        {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                        <div  *ngIf="item.groupAttributeId==='MAC232'"   [ngClass]="{'text-muted': MIPS463Disabled}"  class="col-xs-3 col-sm-4 col-md-3 col-lg-3 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger':isFormSubmitted && !!detailform.controls['MAC232'].errors?.required===true}" for="rdReceivedInhalationalYes">
                                    <input formControlName="MAC232" class="form-check-input" type="radio" value="0" id="rdReceivedInhalationalYes" name="MAC232"  (change)="onChange463PtReceivedInhal($event)">
                                    {{MAC232Text1}}
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-5 col-sm-3 col-md-5 col-lg-5"></div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC232'" [ngClass]="{'text-muted': MIPS463Disabled}"  class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="col-xs-4 offset-xs-5 col-sm-4 offset-sm-5 col-md-4 offset-md-4 col-lg-4 offset-lg-4 pl-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger':isFormSubmitted && !!detailform.controls['MAC232'].errors?.required===true}" for="rdReceivedInhalationalNo">
                                    <input formControlName="MAC232" class="form-check-input" type="radio" value="1" id="rdReceivedInhalationalNo" name="MAC232"  (change)="onChange463PtReceivedInhal($event)">
                                    {{MAC232Text2}}
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-3 col-sm-3 col-md-4 col-lg-4"></div>
                    </div>
                    
                    <div *ngIf="item.groupAttributeId==='MAC235'" [ngClass]="{'text-muted': (MIPS463Disabled || MAC235Disabled)}"  class="form-row pb-1 align-items-center">
                        <div class="col-xs-4 col-sm-5 col-md-4  col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits2===true}">{{item.groupAttributeName}}</label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits2===true}" for="rd2POVYes">
                                    <input formControlName="MAC235" class="form-check-input"  type="radio" value="1" id="rd2POVYes" name="MAC235" (change)="onChange463PtExhibitsPONV($event)">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0">
                            <div class="form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits2===true}" for="rd2POVNo">
                                    <input  formControlName="MAC235" class="form-check-input" type="radio" value="0" id="rd2POVNo" name="MAC235" (change)="onChange463PtExhibitsPONV($event)">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-6 col-lg-6"></div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC237'" [ngClass]="{'text-muted': (MIPS463Disabled || MAC237Disabled)}"  class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits2Dtl===true}" for="chkMedReasTheraphy11">
                                <input formControlName="MAC237" class="form-check-input" value="1" type="radio" id="chkMedReasTheraphy11" name="MAC237" (change)="onChange463PtExhibitsPONVSub($event,'MAC237')">
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC239'" [ngClass]="{'text-muted':(MIPS463Disabled || MAC239Disabled)}"  class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits2Dtl===true}" for="chkMedReasTheraphy22">
                                <input  formControlName="MAC239" class="form-check-input" value="1" type="radio"  id="chkMedReasTheraphy22" name="MAC239" (change)="onChange463PtExhibitsPONVSub($event,'MAC239')" >
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC238'" [ngClass]="{'text-muted': (MIPS463Disabled || MAC238Disabled)}"  class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="form-check">
                            <label  class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits2Dtl===true}" for="chkMedReasTheraphy33">
                                <input formControlName="MAC238" class="form-check-input" value="1" type="radio"  id="chkMedReasTheraphy33" name="MAC238" (change)="onChange463PtExhibitsPONVSub($event,'MAC238')">
                                {{item.groupAttributeName}}
                            </label>
                        </div> 
                    </div>
                </div>
            </div>
            </div>
            <div class="card" >
                <div class="card-header font-weight-bold" style="background-color:#D5DCE6">MIPS 477 - Multi-modal Pain Management (18 & older)</div>
                <div class="card-body">
                    <div class="form-row pb-1 align-items-center">
                        <label class="text-muted">Open & laparoscopic intraabdominal, spinal, pelvic, thoracic, breast, joint, head, neck, orthopedic and fracture repair surgeries</label>
                    </div>
                    <div *ngFor="let item of MIPS477List"> 
                    <div *ngIf="item.groupAttributeId==='MAC223'" class="form-row  pb-1 align-items-center">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" for="chkMIPS477NA">
                                    <input formControlName="MAC223" class="form-check-input" type="checkbox"  id="chkMIPS477NA" name="MAC223" (ngModelChange)="onChangeMIPS477NA($event)" >
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                    </div> 
                    <div *ngIf="item.groupAttributeId==='MAC221'"  [ngClass]="{'text-muted': MIPS477Disabled}" class="form-row pb-1 align-items-center">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && hasErrorNonemergentcase===true}">
                                        {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && hasErrorNonemergentcase===true}" for="rdNonEmergentYes">
                                    <input formControlName="MAC221" class="form-check-input" type="radio" value="1" id="rdNonEmergentYes" name="MAC221" (change)="onChange477MultiPainMgmt($event)">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class="form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger':  isFormSubmitted && hasErrorNonemergentcase===true}" for="rdNonEmergentNo">
                                    <input formControlName="MAC221" class="form-check-input" type="radio" value="0" id="rdNonEmergentNo" name="MAC221" (change)="onChange477MultiPainMgmt($event)">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC222'"  [ngClass]="{'text-muted': (MIPS477Disabled || MAC222Disabled)}" class="form-row pb-0 align-items-center">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class="form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorOpenLog===true}">
                                        {{item.groupAttributeName}}
                                 </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorOpenLog===true}" for="rdNonEmergentYes">
                                    <input formControlName="MAC222" class="form-check-input" type="radio" value="1" id="rdNonEmergentYes" name="MAC222" (change)="onChange477OpenLap($event)">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                            <div class="form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorOpenLog===true}" for="rdNonEmergentNo">
                                    <input formControlName="MAC222" class="form-check-input" type="radio" value="0" id="rdNonEmergentNo" name="MAC222" (change)="onChange477OpenLap($event)">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC222A'"  [ngClass]="{'text-muted': (MIPS477Disabled || MAC222ADisabled)}" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorNonemergentcaseYes===true}" for="chkMultiModalPM">
                                <input formControlName="MAC222A" class="form-check-input" type="radio" value="1" id="chkMultiModalPM" name="MAC222A" (change)="onChange477MultiPainMgmtSub($event,'MAC222A')" >
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="item.groupAttributeId==='MAC222B'"  [ngClass]="{'text-muted': (MIPS477Disabled || MAC222BDisabled)}" class="form-row pb-2 pb-sm-0 pb-md-0 pb-lg-0 align-items-center">
                        <div class="form-check">
                            <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorNonemergentcaseYes===true}" for="chkMedicalreasonMultiModal">
                                <input formControlName="MAC222B" class="form-check-input" type="radio" value="1" id="chkMedicalreasonMultiModal"  name="MAC222B" (change)="onChange477MultiPainMgmtSub($event,'MAC222B')" >
                                {{item.groupAttributeName}}
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            </div>
           
            <div class="card" >
                    <div class="card-header font-weight-bold" style="background-color:#D5DCE6">Quantum 31 - Central Line Ultrasound Guidance (all patients)</div>
                    <div class="card-body">
                       
                            <div *ngFor="let item of Quantum31List;">                          
                                <div *ngIf="item.groupAttributeId==='MAC313'"  class="form-row pb-0 align-items-center pl-0">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pb-1 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <label class="form-check-label" for="chkQuantumNA">
                                                <input formControlName="MAC313" class="form-check-input" type="checkbox"  id="chkQuantumNA" name="MAC313" (ngModelChange)="onChangeQuantuam($event)" >
                                                {{item.groupAttributeName}}
                                            </label>
                                        </div>
                                    </div>
                                </div>   
                                <div [ngClass]="{'text-muted': Quan31Disabled}" >
                        <div  *ngIf="item.groupAttributeId==='MAC306'" [ngClass]="{'text-muted': Quan31Disabled }" class="form-row pb-1 align-items-center">
                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div class=" form-check">
                                    <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC306'].errors?.required===true}">  {{item.groupAttributeName}}</label>
                                </div>
                            </div>
                            <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div class=" form-check">
                                    <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC306'].errors?.required===true}"  for="rdNonEmergentYesMAC306">
                                        <input formControlName="MAC306" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC306" name="MAC306" (change)="onChangeMAC306($event)">
                                        Yes
                                    </label>
                                </div>  
                            </div>
                            <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-1 pb-sm-0 pb-md-0 pb-lg-0">
                                <div class="form-check">
                                    <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC306'].errors?.required===true}" for="rdNonEmergentNoMAC306">
                                        <input formControlName="MAC306" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC306" name="MAC306" (change)="onChangeMAC306($event)">
                                        No
                                    </label>
                                </div>
                            </div>
                            <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                        </div>
                        <div *ngIf="item.groupAttributeId==='MAC307'" [ngClass]="{'text-muted': (Quan31Disabled || MAC307Disabled)}" class="form-row pb-1 align-items-center">
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class="form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC306===true}"> {{item.groupAttributeName}}</label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC306===true}" for="rdNonEmergentYesMAC307">
                                            <input formControlName="MAC307" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC307" name="MAC307" (change)="onChangeMAC307($event)">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-1 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class="form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC306===true}" for="rdNonEmergentNoMAC307">
                                            <input formControlName="MAC307" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC307" name="MAC307" (change)="onChangeMAC307($event)">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC308'" [ngClass]="{'text-muted': (Quan31Disabled || MAC308Disabled)}" class="form-row pb-1 align-items-center">
                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class="form-check">
                                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC307===true}"> {{item.groupAttributeName}}</label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class="form-check">
                                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC307===true}" for="rdNonEmergentYesMACMAC308">
                                                <input formControlName="MAC308" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMACMAC308" name="MAC308" (change)="onChangeMAC308($event)">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0">
                                        <div class="form-check">
                                            <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorsMAC307===true}" for="rdNonEmergentNoMACMAC308">
                                                <input formControlName="MAC308" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMACMAC308" name="MAC308" (change)="onChangeMAC308($event)">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                                </div>  
                            </div>                              
                            </div>   
                    </div>
            </div>
            <div class="card" >
                <div class="card-header font-weight-bold" style="background-color:#D5DCE6">AnesTier 1.x - Application of POM Evidence-based Elements for Total Joint Arthroplasty (18 & older)</div>
                <div class="card-body">
                       
                        <div *ngFor="let item of AnesTierList;"> 
                            <div *ngIf="item.groupAttributeId==='MAC314'"  class="form-row pb-0 align-items-center pl-0">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label" for="chkAnesTierNA">
                                            <input formControlName="MAC314" class="form-check-input" type="checkbox"  id="chkAnesTierNA" name="MAC314" (ngModelChange)="onChangeAnesTier($event)" >
                                            {{item.groupAttributeName}}
                                        </label>
                                    </div>
                                </div>
                            </div> 
                            <div [ngClass]="{'text-muted': AnesTierDisabled}" >
                            <div  *ngIf="item.groupAttributeId==='MAC310'" [ngClass]="{'text-muted': AnesTierDisabled}" class="form-row pb-0 align-items-center"> 
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC310'].errors?.required===true}">  {{item.groupAttributeName}}</label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC310'].errors?.required===true}"  for="rdNonEmergentYesMAC310">
                                            <input formControlName="MAC310" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC310" name="MAC310" (change)="onChangeMAC310($event)">
                                            Yes
                                        </label>
                                    </div>  
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class="form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC310'].errors?.required===true}" for="rdNonEmergentNoMAC310">
                                            <input formControlName="MAC310" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC310" name="MAC310" (change)="onChangeMAC310($event)">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                            </div>
                                                  
                            <div *ngIf="item.groupAttributeId==='MAC311'" [ngClass]="{'text-muted': (AnesTierDisabled || MAC311Disabled)}" class="form-row pb-1 align-items-center">
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0">
                                    <div class=" form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC310===true}"> {{item.groupAttributeName}}</label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC310===true}" for="rdNonEmergentYesMAC311">
                                            <input formControlName="MAC311" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC311" name="MAC311" (change)="onChangeMAC311($event)">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-1 col-lg-1 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class="form-check">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC310===true}" for="rdNonEmergentNoMAC311">
                                            <input formControlName="MAC311" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC311" name="MAC311" (change)="onChangeMAC311($event)">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-4 col-md-6 col-lg-6"></div>
                            </div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC310' && item.hasOwnProperty('groupSubAttribute')">
                                    <div class="form-row">
                                        <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 pl-0 text-muted">
                                            <ul *ngFor="let child of item.groupSubAttribute" >
                                                    <li >
                                                        {{child.groupAttributeSubItem}}
                                                    </li>
                                            </ul>  
                                        </div> 
                                    </div>
                                </div> 
                            <div *ngIf="item.groupAttributeId==='MAC311' && item.hasOwnProperty('groupSubAttribute')">
                                <div class="form-row">
                                    <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 pl-0 text-muted">
                                        <ul *ngFor="let child of item.groupSubAttribute">
                                            <li >
                                                    {{ child.groupAttributeSubItem }}
                                            </li>
                                        </ul> 
                                    </div>
                                </div>
                            </div>
                        </div>   
                </div>
        </div>
        </div>
        <div class="card" >
            <div class="card-header font-weight-bold" style="background-color:#D5DCE6">Other</div>
                <div class="card-body">
                    <div class="form-row pb-2 align-items-center">
                        <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3 ">
                            <label for="drpTransferofcare" class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC706'].errors?.required===true}">Transfer of Care</label>
                        </div>
                        <div class="col-xs-4 col-sm-5 col-md-4 col-lg-4">
                            <select formControlName="MAC706" class="form-control" [ngClass]="{'border-danger': isFormSubmitted && detailform.controls['MAC706'].errors?.required===true}" id="drpTransferofcare">
                                <option *ngFor="let val of this.Transferofcare" value={{val.groupAttributeSubItemId}}>
                                    {{val.groupAttributeSubItem}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xs-5 col-sm-4 col-md-5 col-lg-5"></div>
                    </div>
                    <div class="form-row pb-2 align-items-center">
                        <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3 ">
                            <label for="drpHandoff" class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC707'].errors?.required===true}">Handoff Protocol/checklist followed</label>
                        </div>
                        <div class="col-xs-4 col-sm-5 col-md-4 col-lg-4">
                            <select formControlName="MAC707" class="form-control" [ngClass]="{'border-danger': isFormSubmitted && detailform.controls['MAC707'].errors?.required===true}" name="MAC707" id="drpHandoff">
                                <option *ngFor="let val of this.HandOffProtocol" value={{val.groupAttributeSubItemId}}>
                                    {{val.groupAttributeSubItem}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xs-5 col-sm-4 col-md-5 col-lg-5"></div>
                    </div>
                    <!-- <div class="form-row align-items-center">
                        <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3">
                            <label class="form-check-label">Adult PONV Pt.-Specific Risk Factors</label>
                        </div>
                        <div class="col-sm-1 col-md-1 col-xs-1 col-lg-1">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAdultPONVPatSpecific===true}" for="chkFemale">
                                    <input formControlName="MAC704B" class="form-check-input" type="checkbox"  id="chkFemale" name="MAC704B" (change)="onChangeAdultPONVPatient($event)" >
                                    Female
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-8 col-md-8 col-xs-1 col-lg-1"></div>
                    </div>
                    <div class="form-row align-items-center">
                        <div class="col-sm-7 offset-sm-4 col-xs-4 offset-xs-3 col-md-4 offset-md-3 col-lg-4 offset-lg-3">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAdultPONVPatSpecific===true}" for="chkMotionSickness">
                                    <input formControlName="MAC704C" class="form-check-input" type="checkbox"  id="chkMotionSickness" name="MAC704C" (change)="onChangeAdultPONVPatient($event)" >
                                    History of PONV and Motion Sickness
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-1 col-xs-5 col-md-5 col-lg-5"></div>
                    </div>
                    <div class="form-row align-items-center">
                        <div class="col-sm-4 offset-sm-4 col-xs-4 offset-xs-3 col-md-4 offset-md-3 col-lg-4 offset-lg-3">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAdultPONVPatSpecific===true}" for="chkNonSmoker">
                                    <input formControlName="MAC704D" class="form-check-input"  type="checkbox" id="chkNonSmoker" name="MAC704D" (change)="onChangeAdultPONVPatient($event)">
                                    Non Smoker
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-5 col-xs-5 col-md-5 col-lg-5"></div>
                    </div>
                    <div class="form-row align-items-center">
                        <div class="col-sm-7 offset-sm-4 col-xs-4 offset-xs-3 col-md-4 offset-md-3 col-lg-4 offset-lg-3">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAdultPONVPatSpecific===true}" for="chkOperativeOpioids">
                                    <input formControlName="MAC704E" class="form-check-input" type="checkbox"  id="chkOperativeOpioids" name="MAC704E" (change)="onChangeAdultPONVPatient($event)">
                                    Need for post operative opioids
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-1 col-xs-5 col-md-5 col-lg-5"></div>
                    </div>
                    <div class="form-row align-items-center">
                        <div class="col-sm-4 offset-sm-4 col-xs-4 offset-xs-3 col-md-4 offset-md-3 col-lg-4 offset-lg-3">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAdultPONVPatSpecific===true}" for="chkNotApplicable">
                                    <input formControlName="MAC704A" class="form-check-input" type="checkbox" value="" id="chkNotApplicable" name="MAC704A" (change)="onChangeAdultPONVPatient($event)">
                                    Not Applicable
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-5 col-xs-5 col-md-5 col-lg-5"></div>
                    </div> -->
                </div>
        </div>
        <div *ngIf="!isSignatureDisplay">
            <div class="form-row">
                <div class="col-md-12">
                    <div class="card" >
                        <div class="card-header font-weight-bold" style="background-color:#D5DCE6">Clinician Signature</div>
                        <div class="form-check-label ml-4">
                            <label class="col-form-label pr-3" id="MAC033">{{name}} </label>
                            <label class="col-form-label" id="MAC034">{{date}} </label>
                        </div>
                        <input type="button" formControlName="BtnSign" class="btn btn-outline-danger ml-4 mb-2 mr-4 rounded"  value="Add Signature" (click)="changeDate()">
                        <label [ngClass]="{'text-danger': hasErrorsSignature===true}" *ngIf="signatureError" class="ml-4"> Signature required!</label>
                    </div>
                </div>
            </div>
        </div>
        <p class="text-primary mt-3">Note: To report a clinical occurrence /adverse event go to CareTool Tab</p> 
    </form>
</div>
