import { Component, ViewChild,ElementRef, OnInit} from '@angular/core';
import { MacraComponent } from '../macra/macra.component';
import { CaseinfoComponent } from '../caseinfo/caseinfo.component';
import { CaretoolComponent } from '../caretool/caretool.component';
import { attribute, patientDemographics ,provider, document } from 'src/app/_models/case-details';
import { CaseRecResponse } from 'src/app/_models/case-rec-response';
import { CaseDetails } from 'src/app/_models/case-details';
import { MulesoftAPIServiceService } from 'src/app/_services/mulesoft/mulesoft-apiservice.service';
import { fromEvent,timer } from 'rxjs';
import { catchError, debounceTime, take } from "rxjs/operators";
import { AbstractControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";  
import{ Globalconstants, enumcaseStatus,enumTabs, caseStatusByenumKey } from 'src/app/helper/globalconstants';
import { LogService } from 'src/app/_services/log/log.service';
import { CaseRecStatus } from 'src/app/_models/case-rec-status';
import { formatDate } from '@angular/common';



@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
  

})


export class TabsComponent implements OnInit{

  
  createdBy:string;
  btnClose:boolean = false;
  public arrAttribute: attribute[];
  public arrIndicatorsAttribute: attribute[];
  public FinalarrAttribute: attribute[];
  public CaseRecInfo : CaseRecResponse ; 
  public CaseDtl : CaseDetails;
  public PatientInfo : patientDemographics[];
  public Doc: document[];
  public provider: provider[];
  public IsSuccess : boolean =  false;
  public IsSuccessCaretool : boolean =  false;
  public isSuccessCaseinfo:boolean = false;
  public FieldID : string;
  @ViewChild(MacraComponent) childMacra:MacraComponent;
  @ViewChild(CaseinfoComponent) childCaseInfo:CaseinfoComponent;
  @ViewChild(CaretoolComponent) childCaretool:CaretoolComponent;
  public isErrorAlertAPICall: boolean=false;
  public ErrorMessageAPICall:string ="";
  public SuccessMessage: string;
  public isSuccessAlert: boolean=false;
  public ErrorMessage: string;
  public isErrorAlert: boolean=false;
  public Attributeval : string;
  public AttributeID : string;
  public loading: boolean;
  public InfoMessage: string;
  public isInfoAlert: boolean=false;
  public isDisabled: boolean=false;
  public AdverseEventValue: string;
  public ActiveTab: string;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  public caserecStatus: CaseRecStatus;

  ngOnInit(): void {
  }

  constructor(private apiService: MulesoftAPIServiceService,private el: ElementRef, private SpinnerService: NgxSpinnerService,private logger: LogService) {
      this.ActiveTab = enumTabs.macra;

  }
  selectedTab(tab){
    this.ActiveTab =  tab;
  }

  onClickMsg(strStatus:string)
  {
    if(strStatus=="S")
    {
      this.isSuccessAlert= false;
    }
    else if(strStatus=="I")
    {
      this.isInfoAlert= false;
    }
    else if(strStatus=="A")
    {
      this.isErrorAlert= false;
    }
  }
  
  onClick(strStatus:string): void {
    
try  
{     
  this.isErrorAlertAPICall = false;
  this.ErrorMessageAPICall = ""; 
   
    if(strStatus === Globalconstants.NextEvent){
     //console.log(this.router.navigate);
    // document.getElementById('#tabs a[href="#macra"]').tabIndex = 3
    this.CaretoolClick();
    if(this.ActiveTab == enumTabs.macra){
         this.ActiveCareToolTab();
        } else if(this.ActiveTab == enumTabs.info) {
           this.ActiveMacraTab();
        } 
    }else {
        //console.log(this.childMacra);
        if (this.childMacra) {  
          
          if (strStatus == Globalconstants.CompleteStatus)
          {
              this.IsSuccess = this.childMacra.onSubmit();
              this.IsSuccessCaretool = this.childCaretool.onSubmit();
              this.isSuccessCaseinfo = this.childCaseInfo.onSubmit();

              if(!this.isSuccessCaseinfo){
                 this.ActiveInfoTab();
                 this.scrollToFirstInvalidControl();
                  return;
              }
              if(!this.IsSuccess){
                this.ActiveMacraTab();
                this.scrollToFirstInvalidControl();
                return;
              }
              if(!this.IsSuccessCaretool){
               this.ActiveCareToolTab();
                this.scrollToFirstInvalidControl();
                return;
              }
              // console.log('Testing Here ' + this.IsSuccess);
              // console.log('Caretool Testing Here ' + this.IsSuccessCaretool);
              // console.log('Caseinfo testing ' + this.isSuccessCaseinfo)
            if((this.IsSuccess) && (this.IsSuccessCaretool) && (this.isSuccessCaseinfo))
            //if(this.IsSuccess) 
              {
                this.SaveData(enumcaseStatus.Complete);
                this.ClearMIPSMeasures();
              }
          }
          else
          {
            // check if case rec id exist if not 
            this.isSuccessCaseinfo = this.childCaseInfo.onSubmit();
            this.IsSuccessCaretool = this.childCaretool.onSave();
            if(!this.isSuccessCaseinfo) {
              this.ActiveInfoTab();
              this.scrollToFirstInvalidControl();
              return
            }

            if(!this.IsSuccessCaretool){
              this.ActiveCareToolTab();
              this.scrollToFirstInvalidControl();
              return;
            }

            if(this.isSuccessCaseinfo && this.IsSuccessCaretool) {
             // this.SpinnerService.show();  
              this.SaveData(enumcaseStatus.Incomplete);
              this.ClearMIPSMeasures();
             // this.SpinnerService.hide(); 
            } 
             
          }
        }
      }

    }  
    catch(error)  
    { this.logger.log("Error raised in tabs.component.ts - onClick :ERR::" + error.message);                           
      //this.isErrorAlertAPICall = true;
      //this.ErrorMessageAPICall = Globalconstants.ErrorMessageUI; 
    } 
} 
UpdateCaseRecStatus(caserecID: string, strStatus: string){
    const objCaseRecStatus: CaseRecStatus = {
      caseStatus : strStatus
    };
    this.caserecStatus = objCaseRecStatus;
    try{
        //console.log(strStatus);
        this.apiService.UpdateCaseRecStatus(objCaseRecStatus,caserecID).subscribe(
          statusResult => { 
            if (statusResult.statusCode == "0")
            {
              this.isSuccessAlert = true;
              if(strStatus == Globalconstants.InCompleteStatus)
              {
                   this.SuccessMessage = Globalconstants.SuccessMessage;
              }
              else{
                   this.SuccessMessage = Globalconstants.SubmitMessage;
              }
               //console.log(statusResult);
                setTimeout(() => {
                  this.preventAbuse = false;
                }, 500);
               return true;
            } else{
              //console.log(statusResult);
                this.isErrorAlert = true;                
                this.ErrorMessage = Globalconstants.StatusErrorMessage; //"Record failed to save!!";
                //scroll to top of screen
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
                return false;
            }
            

          },
          error => {console.error(error); this.isErrorAlertAPICall = true; this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},
          );
    }
    catch(error)  
    { 
      this.logger.log("Error raised in tabs.component.ts - on UpdateCaseRecStatus :ERR::" + error.message);                           
    } 

}
  //Activate Info tab
  ActiveInfoTab(){
      this.ActiveTab = enumTabs.info;
      document.getElementById("caretool-tab").className="nav-link";
      document.getElementById("macra-tab").className="nav-link";
      document.getElementById("info-tab").className="nav-link active";
      document.getElementById("caretool").className="tab-pane fade ";
      document.getElementById("macra").className ="tab-pane fade";
      document.getElementById("info").className="tab-pane fade show active";
  }

  ActiveCareToolTab(){
      this.ActiveTab = enumTabs.caretool;
      document.getElementById("caretool-tab").className="nav-link active";
      document.getElementById("macra-tab").className="nav-link";
      document.getElementById("info-tab").className="nav-link";
      document.getElementById("caretool").className="tab-pane fade show active";
      document.getElementById("macra").className ="tab-pane fade ";
      document.getElementById("info").className="tab-pane fade";
  }

  ActiveMacraTab(){
      this.ActiveTab = enumTabs.macra;
      document.getElementById("caretool-tab").className="nav-link";
      document.getElementById("macra-tab").className="nav-link active";
      document.getElementById("info-tab").className="nav-link";
      document.getElementById("caretool").className="tab-pane fade";
      document.getElementById("macra").className ="tab-pane fade  show active";
      document.getElementById("info").className="tab-pane fade";
  }

  DisableAllTabs(){
    document.getElementById("caretool-tab").className="nav-link disabled";
    document.getElementById("macra-tab").className="nav-link disabled";
    document.getElementById("info-tab").className="nav-link disabled";
    document.getElementById("caretoolheader").className="nav-item disabled";
    document.getElementById("macraheader").className="nav-item disabled";
    document.getElementById("infoheader").className="nav-item disabled";
    document.getElementById("macra").className ="tab-pane fade disabled";
    document.getElementById("caretool").className ="tab-pane fade disabled";
    document.getElementById("info").className ="tab-pane fade disabled";
  }


  ClearMIPSMeasures()
  {
    if(this.childMacra.detailform.get('MAC700').value ==="0")
    {
    this.childMacra.detailform.get('MAC022B').reset();
    this.childMacra.detailform.get('MAC216').reset();
    this.childMacra.detailform.get('MAC217').reset();
    this.childMacra.detailform.get('MAC218').reset();
    this.childMacra.detailform.get('MAC219').reset();
    this.childMacra.detailform.get('MAC201').reset();
    this.childMacra.detailform.get('MAC202').reset();
    this.childMacra.detailform.get('MAC014').reset();
    this.childMacra.detailform.get('MAC015').reset();
    this.childMacra.detailform.get('MAC015A').reset();
    this.childMacra.detailform.get('MAC015B').reset();
    this.childMacra.detailform.get('MAC022').reset();
    this.childMacra.detailform.get('MAC023').reset();
    this.childMacra.detailform.get('MAC022A').reset();
    this.childMacra.detailform.get('MAC022B').reset();
    this.childMacra.detailform.get('MAC032').reset();
    this.childMacra.detailform.get('MAC032A').reset();
    this.childMacra.detailform.get('MAC032B').reset();
    this.childMacra.detailform.get('MAC032C').reset();
    this.childMacra.detailform.get('MAC221').reset();
    this.childMacra.detailform.get('MAC224').reset();
    this.childMacra.detailform.get('MAC222').reset();
    this.childMacra.detailform.get('MAC223').reset();
    this.childMacra.detailform.get('MAC232').reset();
    this.childMacra.detailform.get('MAC235').reset();
    this.childMacra.detailform.get('MAC237').reset();
    this.childMacra.detailform.get('MAC239').reset();
    this.childMacra.detailform.get('MAC238').reset();
    this.childMacra.detailform.get('MAC033').reset();
    this.childMacra.detailform.get('MAC034').reset();
    this.childMacra.detailform.get('MAC226').reset();
    this.childMacra.detailform.get('MAC227').reset();
    this.childMacra.detailform.get('MAC228').reset();
    this.childMacra.detailform.get('MAC231').reset();
    this.childMacra.detailform.get('MAC229').reset();
    }

  }

  CaretoolClick()
  {
    
try  
{     
  this.isErrorAlertAPICall = false;
  this.ErrorMessageAPICall = ""; 
    //console.log('craetooltab called');   
    var iASA =  JSON.parse(sessionStorage.getItem('ASAStatus'));                        
    let iASAdata = iASA.find(ob => ob['groupAttributeSubItemId'] === this.childMacra.detailform.get('MAC702').value);                        
    if(iASAdata)
    { 
      //console.log(iASAdata);
      var ASACla =  JSON.parse(sessionStorage.getItem('ASAClasificationArrList'));      
      let ASAdata = ASACla.find(ob => ob['groupAttributeName'] === iASAdata.groupAttributeSubItem);                        
      //console.log(ASAdata);
      if(ASAdata)
      {                          
        this.childCaretool.caretoolform.get('drpASA').setValue(ASAdata.groupAttributeId);
        this.childCaretool.caretoolform.get('drpASA').disable();
      }
    }
    else{
      // this.childCaretool.caretoolform.get('drpASA').reset();
      // this.childCaretool.caretoolform.get('drpASA').enable();
      // this.childCaretool.caretoolform.get('drpASA').setValue('');
    }


    //Anesthesia Type
    var iAnesType =  JSON.parse(sessionStorage.getItem('AnesTypeDocList'));                        
    let iAnesTypedata = iAnesType.find(ob => ob['groupAttributeSubItemId'] === this.childMacra.detailform.get('MAC701').value);                        
    if(iAnesTypedata)
    { 
      //console.log(iASAdata);
      var ASACla =  JSON.parse(sessionStorage.getItem('AnesTypeArrList'));      
      let ASAdata = ASACla.find(ob => ob['groupAttributeName'] === iAnesTypedata.groupAttributeSubItem);                        
      //console.log(ASAdata);
      if(ASAdata)
      {                          
        this.childCaretool.caretoolform.get('drpAnesthesiaType').setValue(ASAdata.groupAttributeId);
        this.childCaretool.caretoolform.get('drpAnesthesiaType').disable();
      }
    }
    else{
      // this.childCaretool.caretoolform.get('drpAnesthesiaType').reset();
      // this.childCaretool.caretoolform.get('drpAnesthesiaType').enable();
      // this.childCaretool.caretoolform.get('drpAnesthesiaType').setValue('');
    }

    // Emergency field set..
    if(this.childMacra.detailform.get('MAC703').value)
    {
      this.childCaretool.caretoolform.get('CT533').setValue(this.childMacra.detailform.get('MAC703').value);
      this.childCaretool.caretoolform.get('CT533').disable();
    }
    else{
      this.childCaretool.caretoolform.get('CT533').reset();
      this.childCaretool.caretoolform.get('CT533').enable();
    } 

  }  
  catch(error)  
  { this.logger.log("Error raised in tabs.component.ts - onClick :ERR::" + error.message);                           
    //this.isErrorAlertAPICall = true;
    //this.ErrorMessageAPICall = Globalconstants.ErrorMessageUI; 
  } 


  }

  private getName(control: AbstractControl): string | null {
    let group = <FormGroup>control.parent;
    if (!group) {
      return null;
    }
    let name: string;
    Object.keys(group.controls).forEach(key => {
      let childControl = group.get(key);
      if (childControl !== control) {
        return;
      }
      name = key;
    });
    return name;
  }
  
  SaveData(strStatus:string)
  { 
          
      try  
      {     
        this.isErrorAlertAPICall = false;
        this.ErrorMessageAPICall = "";          
          this.Doc= [];  
        
          //console.log(this.childCaretool.DocumentList());
          this.Doc = this.childCaretool.DocumentList();
          //console.log(this.Doc.length);
          this.provider = [];
          const objProvider: provider = {
              providerFirstName:  this.childCaseInfo.detailform.get('ClinicianFirstName').value,
              providerLastName:   this.childCaseInfo.detailform.get('ClinicianLastName').value,
              providerMiddleName: '',
              providerId:         '',
              providerNPI:        '',
              //providerRole:       '',
              providerTitle:      '',
              isManualEntry:      '',
              //isPrimary:          '',
              email:              this.childCaseInfo.detailform.get('Email').value,
              phone:              this.childCaseInfo.detailform.get('Phone').value,
              serviceLine:        '',
              caserecProvider:  JSON.parse('true')
        };
        
          const objTmpPatinetDtl: patientDemographics = {
              patientFirstName: this.childCaseInfo.detailform.get('FirstName').value,
              patientLastName: this.childCaseInfo.detailform.get('LastName').value,
              patientMiddleInitial: this.childCaseInfo.detailform.get('MiddleName').value,
              patientDob : this.childCaseInfo.detailform.get('DOB').value,
              patientDos : this.childCaseInfo.detailform.get('DOS').value,
              gender:   this.childCaseInfo.detailform.get('Gender').value,
              patientAccountNumber:        '',
              mrn:  this.childCaseInfo.detailform.get('MRN').value,
              patientIdentificationNumber:  '',
              race:this.childCaseInfo.detailform.get('Race').value,
              postInductionCancellationFlag :  JSON.parse(this.childCaseInfo.detailform.get('hdnpostInductionCancellationFlag').value ) 
          };
          this.arrAttribute = this.childMacra.SubmitMacra();  

          for (const field in this.childCaretool.caretoolform.controls) { // 'field' is a string  
          this.FieldID  = this.getName(this.childCaretool.caretoolform.controls[field]);          
                  if ((this.FieldID==="CT533") || (this.FieldID==="CT548") || (this.FieldID==="CT549") || (this.FieldID==="CT550") || (this.FieldID==="CT000") || (this.FieldID==="CT605") || (this.FieldID==="CT607") || (this.FieldID==="CT608") )
                  {
                    this.AttributeID = this.getName(this.childCaretool.caretoolform.controls[field]);
                    if (this.childCaretool.caretoolform.controls[field].value==true)
                    {
                      this.Attributeval = "1";
                    }
                    else{
                    //this.Attributeval =this.childCaretool.caretoolform.controls[field].value;
                    if(this.FieldID==="CT605")
                          {                            
                            this.Attributeval = formatDate(this.childCaretool.caretoolform.controls[field].value, 'MM/dd/yyyy', 'en_US');
                          }
                          else{
                              this.Attributeval =this.childCaretool.caretoolform.controls[field].value;
                              }
                    }
                  }
                  else
                  {
                    this.AttributeID = this.childCaretool.caretoolform.controls[field].value;
                    if(this.childCaretool.caretoolform.controls[field].value == true)
                    {              
                      this.Attributeval ="";
                    }
                    else{
                      this.Attributeval ="1";
                    }
                  }
                  const objTmp: attribute = {              
                    attributeId: this.AttributeID,              
                    attributeValue: this.Attributeval ,
                    attributeAdditionalNotes:"",
                    attributeType: "Caretool"   
                  };
                      if((objTmp.attributeId) && (objTmp.attributeValue))
                      {  
                          if(objTmp.attributeId.toString().length<11)
                          {                                                      
                            this.arrAttribute.push(objTmp);  
                          }                   
                      }
                      if(objTmp.attributeId === 'CT000' && objTmp.attributeValue ==="0") 
                          { break;}                
                  }
                 
              if (sessionStorage.getItem('loggedInUser'))
              {this.createdBy = sessionStorage.getItem('loggedInUser');}

              this.arrIndicatorsAttribute = this.childCaretool.getDataSave();
              if(this.arrIndicatorsAttribute)
              {
                  this.FinalarrAttribute = this.arrAttribute.concat(this.arrIndicatorsAttribute);
              }
              else
              {
                this.FinalarrAttribute = this.arrAttribute;
              }
           //console.log(this.childCaseInfo.detailform.get('hdngeMnemonic').value);
          this.CaseDtl = {
            //caseRecUniqueId:     '',
            createdBy:this.createdBy,
            facilityId:        this.childCaseInfo.detailform.get('hdnfacilityid').value,      
            status:            strStatus,
            serviceLine : this.childCaseInfo.detailform.get('Serviceline').value,
            facilityName : this.childCaseInfo.detailform.get('Facility').value,
            attributes :this.FinalarrAttribute,
            patientDemographics : objTmpPatinetDtl,
            documents : this.childCaretool.DocumentList(),
            providers : this.childCaretool.ClinicianList(),
            gemnemonic : this.childCaseInfo.detailform.get('hdngeMnemonic').value
        }
      
       //console.log(this.CaseDtl);
        //Need to replace hard coded value with casrecid from caserec api
          this.apiService.saveCaseDetails(this.CaseDtl, sessionStorage.getItem('caserecid')).subscribe(
            result => { 
            // console.log(strStatus);
            // console.log(Globalconstants.CompleteStatus);
              if (result.statusCode == "0" && result.status == Globalconstants.SuccessStatus)
              {
                setTimeout(() => {
                  this.preventAbuse = false;
                }, 500);

                //console.log('this.isSuccessAlert = true;');
                
                if(strStatus === enumcaseStatus.Complete){
                  //this.isSuccessAlert = true;
                 // this.SuccessMessage = Globalconstants.SubmitMessage;
                  this.isDisabled = true;
                  this.btnClose = true;
                  this.DisableAllTabs();
                  this.UpdateCaseRecStatus(sessionStorage.getItem('caserecid'),caseStatusByenumKey.get(enumcaseStatus.Complete));
                } else{
                  //this.isSuccessAlert = true;
                  //this.SuccessMessage = Globalconstants.SuccessMessage;
                  this.UpdateCaseRecStatus(sessionStorage.getItem('caserecid'),caseStatusByenumKey.get(enumcaseStatus.Incomplete));
                }
                //scroll to top of screen
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
                // alert('Record Saved Successfully!!');
              }
              else{
                this.isErrorAlert = true;
                this.ErrorMessage = Globalconstants.ErrorMessage; //"Record failed to save!!";
                //scroll to top of screen
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
              // alert('Record Not Saved Successfully!!');
              }         
          },
          error => {console.error(error); this.isErrorAlertAPICall = true; this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},
        
          );

  }  
  catch(error)  
  { this.logger.log("Error raised in MACRA.component.ts - SubmitMacra :ERR::" + error.message);                           
    //this.isErrorAlertAPICall = true;
    //this.ErrorMessageAPICall = Globalconstants.ErrorMessageUI; 
  } 
    

  }

  closeWindowtab(){
    var x=confirm(Globalconstants.ConfirmMessage);
   // alert(x);
    if(x) 
    {
      sessionStorage.clear(); 
      window.top.close();      
    }
  }

  closeWindow()
  {   
    var x=confirm(Globalconstants.CloseMessage);
    // alert(x);
     if(x) 
     {
       sessionStorage.clear(); 
       window.top.close();
     }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      ".text-danger"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });

    fromEvent(window, "scroll")
      .pipe(
        debounceTime(500),
        take(1)
      )
      .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    //console.log("In Tabs:",controlEl);
    if(controlEl != null) {
      const labelOffset = 50;
      //return window.scrollY - labelOffset;
      return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }
  }
   //progress bar on started
   onStarted() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 500);
  }
 //progress bar on completed.
  onCompleted() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 500);
  }

}
