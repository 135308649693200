export class Globalconstants {
    public static SuccessMessage: string = "Record successfully saved. Please return later to complete and submit.";
    public static SubmitMessage: string = "Record successfully submitted. If data is partial you will receive an email within 48 hours with a link to complete CareTool PSRS Form.";
    public static InfoMessage: string = "The MACRA and CareTool is already submitted for this case. For further changes to the submitted forms, please use IQ application or contact Quality Business -  AnesQuality@shcr.com";
    public static ErrorMessage: string = "Record failed to save!!";
    public static ConfirmMessage: string = 'Are you sure you want to cancel? The data entered will not be saved.';
    public static CloseMessage: string = 'Are you sure you want to close?';
    public static LogOutMessage: string = 'Are You sure want to Logout!';
    public static StatusSuccessMessage: string = "Status updated successfully!!";
    public static StatusErrorMessage: string = "failed to update Status!!";
    public static CompleteStatus: string = "Complete";
    public static InCompleteStatus: string = "Incomplete";
    public static SuccessStatus: string = "success";
    public static NextEvent: string = "Next";
    public static ErrorMsgClinician: string ="Please Select Clinician!";
    public static ErrorMsgClinicianAdded: string = "Clinician already added!";
    public static ErrorMsgIndicators:string = "Please select clinical indicators!" 
    public static ErrorMsgIndicatorsChild:string ="Please Select Secondary Indicator!";
    public static ErrorMsgPastMedicalHistory:string = "Please select any Past Medical History!";
    public static ErrorMsgPastMedicalHistoryChild:string = "Please Select Secondary Past Medical History!";
    public static FileSizemessage:string = 'File size cannot be greater than 15MB';
    public static ValidFilemessage:string = 'Please select valid file to upload';
    public static FileUploadmessage:string ="File uploaded successfully!";
    public static FileNotUploadmessage:string = "couldn't upload the files!";
    public static FileDeletemessage:string ="File deleted successfully!";
    public static FileConfirmmessage:string = 'Are You sure you want to delete selected file!';
    public static appName:string = 'CentralizedMACRAAndCaretool';
    public static inValidFileTypes :string = "exe, bat, dll, js";
    public static ErrorMessageAPICall :string="Technical Error. Please contact Administrator!";   
    public static ErrorMessageUI :string="Technical error in UI. please contact Administrator.!";  
    public static FileAlreadyupload:string = 'Same file is already uploaded!';
    public static UnAuthorizedMessage:string="You do not have access to Centralized Quality Forms;For More Information, Contact AnesQuality@shcr.com";
    public static SessionTimeOutMessage:string = "Your session is expired. Please go to Case Rec App to relaunch the Centralized Quality Forms for the case.";
    public static minDtforDOS ='01/01/1800';
    public static AQIListKey =  "AQI xx - Intraoperative Antibiotic Redosing (18 & older)";
    public static AnesTierListKey =  "AnesTier 1.x - Application of POM Evidence-based Elements for Total Joint Arthroplasty (18 & older)";
    public static Quantum31ListKey =  "Quantum 31 - Central Line Ultrasound Guidance (all patients)";
    public static MIPS44ListKey = "MIPS 44 - Perioperative Beta Blocker in patients with isolated CABG Surgery (18 & Older)";
    public static MIPS76ListKey = "MIPS 76 - Prevention of Central Venous Catheter (CVC) Related Bloodstream Infections (all patients)";
    public static MIPS404ListKey  =  "MIPS 404 - Anesthesiology Smoking Abstinence (18 & Older)";
    public static MIPS424ListKey  = "MIPS 424 - Perioperative Temperature Management (all patients)";                   
    public static MIPS430ListKey = "MIPS 430 - Prevention of PONV - Combination Therapy Adults (18 & older)";
    public static MIPS463ListKey = "MIPS 463 - Prevention of PONV - Combination Therapy Pediatrics (3-17 yrs old)";
    public static MIPS477ListKey =  "MIPS 477 - Multi-modal Pain Management (18 & older)";
    public static AnesTypeDocListKey  ="Anesthesia Quality Evaluation";
    public static RiskScoreKey = "Other";
    public static AgeKey = "Age";
    public static ClinicalIndicatorsKey ="Clinical Indicators";
    public static PastMedicalHistoryKey = "Past Medical History";
    public static AnesTypeArrListKey = "Anesthesia Type";
    public static PatinetTypeArrListKey = "Patient Type";
    public static TimeElapsedArrListKey = "Time Elapsed from DOS to Occurrence";
    public static ClinicalOutcmArrListKey = "Clinical Outcome";
    public static ASAClasificationArrListKey = "ASA Status";
    public static SourceofReportArrListKey = "Source of Report";
    public static HeightUnitArrListKey = "Height Unit";
    public static WeightUnitArrListKey = "Weight Unit";
    
}
export enum enumcaseStatus{
    Incomplete = '102',
    Complete  = '101'  
  }

export enum enumTabs{
    macra = 'macra',
    caretool  = 'caretool',
    info = 'info'
  }

  export enum enumAdverseEventValue{
    No = "0",
    Yes = "1",
    Empty = "2"
 }
export type enumcaseStatusKey = keyof typeof enumcaseStatus;

export const caseStatusByenumKey = new Map<enumcaseStatus, enumcaseStatusKey>(
    Object.entries(enumcaseStatus).map(([key, value]:[enumcaseStatusKey, enumcaseStatus]) => [value, key])
)
