export const environment = {
    production: false,
    caseDetailsByIDAPIUrl: 'https://test-integrations.evhc.net/api/v1/caretool/cases/',
    saveCaseDetailsAPIUrl: 'https://test-integrations.evhc.net/api/v1/caretool/cases/',
    providerInfoAPIUrl: 'https://test-integrations.evhc.net/api/v1/caretool/providers',
    formValuesAPIUrl:' https://test-integrations.evhc.net/api/v1/caretool/cases/formvalues',
    clientIDValue:'',
    clientSecretValue:'',
    caserecPatientDemographicAPIUrl:'https://test-integrations.evhc.net/api/v1/caretool/caserec/cases/',
    fileuploadUrl: 'https://test-integrations.evhc.net/api/v1/caretool/cases/'
};