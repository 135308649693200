import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import  jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {
  QueryValue: string;  
  caseRecID: string;
  constructor(private router: ActivatedRoute, private route: Router) { 
    this.getAuthToken();
  }

  ngOnInit(): void {

  }
  refreshToken(){

  }

  getAuthToken(){
    
    //console.log("router:", this.router);
    this.QueryValue = this.router.snapshot.queryParams['access_token'];
    this.caseRecID = this.router.snapshot.queryParams['caserecid'];
    //this.QueryValue='eyJhbGciOiJIUzI1NiIsImtpZCI6ImpmZGpmdXItMjEyIiwidHlwIjoiSldUIn0.eyJzdWIiOiJqd3QtdGVzdCIsImp0aSI6IjIzNDE0MzUiLCJuYmYiOjE2MDczODg4MjMsImV4cCI6MTYwNzM5OTYyMywiaWF0IjoxNjA3Mzg4ODIzLCJpc3MiOiJDYXNlUmVjb25jaWxpYXRpb24iLCJhdWQiOiJDYXJldG9vbC9NYWNyYSJ9.O3WttXXzZFIsZXJ2rRYEFv0UbUoNUX64gw2DLz0hFes';
    // this.caseRecID = '492';
    if (this.QueryValue) {
      if(this.checkTokenExpirationIsValid(this.QueryValue) && this.checkTokenIssuerIsValid(this.QueryValue)){
        sessionStorage.removeItem('accesstoken')
        sessionStorage.removeItem('caserecid')
        sessionStorage.setItem('accesstoken', this.QueryValue);
        sessionStorage.setItem('caserecid', this.caseRecID);
        this.route.navigate(['/home']);
      } else{
          this.route.navigate(['/error'], { queryParams: { page: 'unAuthorized' } });
      }
    }else{
       this.route.navigate(['/error'], { queryParams: { page: 'unAuthorized' } });
    }
  }

  checkTokenExpirationIsValid(token: string): boolean{
    let decodedToken: any ={};
    //decode the token
    decodedToken = jwt_decode(token);
    //check if the token is valid
    if(decodedToken.exp === undefined){
      this.route.navigate(['/error'], { queryParams: { page: 'unAuthorized' } });
    }

    
    //Get Current Date Time
    const date = new Date(0);
    //Convert exp time to UTC
    let tokenExpDate = date.setUTCSeconds(decodedToken.exp);
    //check if current date and time in seconds is greater than exp
    //if greater than route to home screen else route to error screen
     if(tokenExpDate.valueOf() > new Date().valueOf()){
        return true;
     } else {
        return false;
     }

  }

  checkTokenIssuerIsValid(token: string): boolean{
    let decodedToken: any ={};
    //decode the token
    decodedToken = jwt_decode(token);
    //Check if token issue is caserec if not return false
    if(decodedToken.iss === 'CaseReconciliation'){
      return true;
    } else{
      return false;
    }
  }
  
}
