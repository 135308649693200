import { Component, OnInit, ElementRef} from '@angular/core';
import { CaseRecResponse } from 'src/app/_models/case-rec-response';
import {  FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { debounceTime, take } from "rxjs/operators";
import { fromEvent } from 'rxjs';
import { MulesoftAPIServiceService } from 'src/app/_services/mulesoft/mulesoft-apiservice.service'; 
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-caseinfo',
  templateUrl: './caseinfo.component.html',
  styleUrls: ['./caseinfo.component.css']
})
export class CaseinfoComponent implements OnInit {
  public CaseRecInfo: CaseRecResponse;
  detailform: FormGroup; 
 // result:any; 
  public loggedInUser: string;
  hasErrors: boolean = false;
  constructor(private fb: FormBuilder,private apiService: MulesoftAPIServiceService, private el: ElementRef) {
    this.detailform = this.fb.group({
      Serviceline: new FormControl({value:'', disabled:true}),
      Facility: new FormControl({value:'', disabled:true}),
      ClinicianFirstName: new FormControl({value:'', disabled:true}),
      ClinicianLastName: new FormControl({value:'', disabled:true}),
      Email: new FormControl({value:'', disabled:true}),
      Phone:new FormControl({value:'', disabled:true}),
      FirstName: new FormControl({value:'', disabled:true}),
      LastName: new FormControl({value:'', disabled:true}),
      Gender: new FormControl({value:'', disabled:true}),
      MRN:new FormControl({value:'', disabled:true}),
      DOB: new FormControl({value:'', disabled:true}),
      DOS: new FormControl({value:'', disabled:true}),
      Race: new FormControl({value:'', disabled:true}),
      txtCaseRecID: new FormControl({value:'', disabled:true}), 
      hdnfacilityid: new FormControl({value:'', disabled:true}),
      MiddleName:new FormControl({value:'', disabled:true}),
      hdnpostInductionCancellationFlag: new FormControl({value:'', disabled:true}),
      hdngeMnemonic:new FormControl({value:'', disabled:true})
    });
    //this.detailform.get('Serviceline').setValue('Anes');

    //Dummy JSON for Patient Information.
   // this.result = { "caseid": sessionStorage.getItem('caserecid'), "casetype":"Anesthesia", "providerNPI":"123456", "providerFirstName":"Mike", "providerLastName":"John", "phone":"1234456", "email":"mike.john@shcr.com",	 "patientDOS":"2020-01-01", "facilityName":"ADVANCEDSRGYCTROCONNOR", "facilityId":"2345", "gemnemonic":"",	"patientDemographics":{"mrn":"ANH83023",	"patientName":{"patientFirstName":"ANHANH","patientMiddleName":"j",	"patientLastName":"VUONG"},"patientDOB":"07-14-1957",	"gender":"Male" },	"anesthesiaType":"Other","asaLevel":"1","emergency":"1","postInductionCancellationFlag":"false"};

     //console.log(sessionStorage.getItem('caserecid'));
     this.apiService.getCaserecPatientDemographicDetails(sessionStorage.getItem('caserecid')).subscribe(
      result => { 
              //console.log("CaseRec API: ", result) ;   
              if(result != undefined) {
                this.detailform.get('Serviceline').setValue(result.caseType);
                this.detailform.get('Facility').setValue(result.facilityName);
                this.detailform.get('ClinicianFirstName').setValue(result.providerFirstName);
                this.detailform.get('ClinicianLastName').setValue(result.providerLastName);
                this.detailform.get('Email').setValue(result.providerEmail);
                this.detailform.get('Phone').setValue(result.providerPhone);
                this.detailform.get('FirstName').setValue(result.patientDemographics.patientName.patientFirstName);
                this.detailform.get('LastName').setValue(result.patientDemographics.patientName.patientLastName);
                this.detailform.get('MiddleName').setValue(result.patientDemographics.patientName.patientMiddleInitial);
                if(result.patientDemographics.patientDOB)
                {
                  let dateToSplit = result.patientDemographics.patientDOB.split("-");
                  let concatenatedDate = dateToSplit[0] + '/' + dateToSplit[1] + '/'+ dateToSplit[2];
                  this.detailform.get('DOB').setValue(formatDate(concatenatedDate, 'MM/dd/yyyy', 'en_US'));
                }
                //this.detailform.get('DOB').setValue(result.patientDemographics.patientDOB);
                this.detailform.get('DOS').setValue(formatDate( result.patientDOS, 'MM/dd/yyyy', 'en_US'));
                this.detailform.get('Gender').setValue(result.patientDemographics.gender);
                this.detailform.get('MRN').setValue(result.patientDemographics.mrn);  
                this.detailform.get('txtCaseRecID').setValue(sessionStorage.getItem('caserecid'));  
                this.detailform.get('hdnfacilityid').setValue(result.facilityId); 
                this.detailform.get('hdnpostInductionCancellationFlag').setValue(result.postInductionCancellationFlag)
                this.detailform.get('hdngeMnemonic').setValue(result.geMnemonic);
                this.loggedInUser = (result.providerFirstName + ' ' + result.providerLastName);
        
                sessionStorage.removeItem('loggedInUser');   
                sessionStorage.setItem("loggedInUser", (result.providerFirstName + ' ' + result.providerLastName));
               
                //"anesthesiaTypeDOC":"Other","asaStatusDOC":"6","emergency":"1"
        
                sessionStorage.removeItem('anesthesiaTypeDOC');   
                sessionStorage.setItem("anesthesiaTypeDOC", result.anesthesiaType)
        
                sessionStorage.removeItem('asaStatusDOC');   
                sessionStorage.setItem("asaStatusDOC", result.asaLevel)
                
                sessionStorage.removeItem('emergency');   
                sessionStorage.setItem("emergency", result.emergency)
              }      
          },
          error => console.error(error)
        );
    }
  
   // verify if caserecid text control is empty or has value
   onSubmit(){   
          if(sessionStorage.getItem('caserecid') == null){
            this.hasErrors = true;
          // this.scrollToFirstInvalidControl();
            return false;
          } 
            return true;
  }
  //onclick of search caserec fecthes all info from the database if exist
  search(){
      if(this.detailform.get('txtCaseRecID').value === '' || this.detailform.get('txtCaseRecID').value === null){
        this.hasErrors = true;
        //this.scrollToFirstInvalidControl();
        
      } else{
        sessionStorage.removeItem('caserecid');
        sessionStorage.setItem("caserecid",this.detailform.get('txtCaseRecID').value);    
        window.location.reload(); 
      }
  }

  ngOnInit(): void {
  }
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      " .text-danger"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });

    fromEvent(window, "scroll")
      .pipe(
        debounceTime(500),
        take(1)
      )
      .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    //console.log("In Caseinfo");
    const labelOffset = 50;
    //return window.scrollY - labelOffset;
   return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

}
