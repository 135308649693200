import { Component, OnInit, ElementRef, Inject, ViewChild } from '@angular/core';
import { MulesoftAPIServiceService } from 'src/app/_services/mulesoft/mulesoft-apiservice.service';
import { attribute  } from 'src/app/_models/case-details';
import {  FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from "rxjs/operators";
import { Output, EventEmitter } from '@angular/core'; 
import { GroupSubAttribute } from 'src/app/_models/request-lookup-attributes';
import { TabsComponent } from 'src/app/tabs/tabs.component';
import{ Globalconstants,enumcaseStatus } from 'src/app/helper/globalconstants';
import { LogService } from 'src/app/_services/log/log.service';
import { GroupAttribute } from 'src/app/_models/request-lookup-attributes';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-macra',
  templateUrl: './macra.component.html',
  styleUrls: ['./macra.component.css']
})
export class MacraComponent implements OnInit  {
  //@Output() someEvent = new EventEmitter<string>();
  
   
  //isNameSelected: boolean;
  //hasErrorMAC306= false;   
  CaseRecAge:string='';
  DBAge:string='';
  MAC015Disabled:boolean = false; 
  MAC015ADisabled:boolean = false; 
  MAC015BDisabled:boolean = false;        
  MAC311Disabled:boolean = false; 
  MAC307Disabled:boolean = false;   
  MAC308Disabled:boolean = false;   
  MAC222Disabled:boolean = false;   
  MAC222ADisabled:boolean = false;   
  MAC222BDisabled:boolean = false;      
  MAC235Disabled:boolean = false;      
  MAC237Disabled:boolean = false;      
  MAC238Disabled:boolean = false;      
  MAC239Disabled:boolean = false;      
  MAC227Disabled:boolean = false;      
  MAC228Disabled:boolean = false;      
  MAC229Disabled:boolean = false;      
  MAC231Disabled:boolean = false;      
  MAC304Disabled:boolean = false;
  MAC305Disabled:boolean = false;
  MAC305ADisabled:boolean = false;
  MAC305BDisabled:boolean = false;
  MAC032ADisabled:boolean = false;
  MAC032BDisabled:boolean = false;
  MAC032CDisabled:boolean = false;
  MAC022ADisabled:boolean = false;
  MAC022BDisabled:boolean = false;
  MAC022CDisabled:boolean = false;
  MIPS76Disabled:boolean = false;
  MIPS242Disabled:boolean = false;
  MIPS404Disabled:boolean = false;
  AQIDisabled:boolean = false;
  MIPS430Disabled:boolean = false;
  MIPS463Disabled:boolean = false;
  MIPS477Disabled:boolean = false;
  AnesTierDisabled:boolean = false;
  Quan31Disabled:boolean = false;
  AgeInYear: number;
  MAC232Text1: string;
  MAC232Text2: string;
  hasErrorsMAC310 = false;
  hasErrorAQI304 =  false;
  hasErrorAQIPtreceived =  false;
  public isErrorAlertAPICall: boolean=false;
  public ErrorMessageAPICall:string = "";
  AdditionalNote:string;
  public name:any;
  public date:any;
  //result:any;
  today= new Date();
  isDisplay = false;
  isANESQualitySectionDisplay = false;
  hasErrorAQI =  false;
  hasErrorAQIProcedure = false;
  isSignatureDisplay = false;
  isFormSubmitted:boolean  = false;
  hasErrorsSignature:boolean = false;
  hasErrors:boolean = false;
  hasErrorsMAC306:boolean = false;
  hasErrorsMAC307:boolean = false;
  hasErrorElectiveCase:boolean = false;
  hasErrorsCentral:boolean = false;
  hasErrorsPtcurrentsmoker:boolean = false;
  hasErrorPtreceivedprior:boolean = false;
  hasErrorPtabstained:boolean = false;
  hasErrorPtabstainedDOS:boolean = false;
  hasErrorAnesCase60:boolean = false;
  hasErrorPatientreceivedinhal:boolean = false;
  hasErrorPatientexhibits3 :boolean = false;
  hasError232: boolean = false;
  hasErrorPatientexhibits2 :boolean = false;

  hasErrorPatientexhibits2Dtl :boolean = false;
  hasErrorNonemergentcase:boolean = false;
  hasErrorNonemergentcaseYes:boolean = false;
  hasErrorOpenLog:boolean = false;
  hasErrorAdultPONVPatSpecific:boolean = false;
  Success: boolean   = false;
  get f() { return this.detailform.controls; }
  ActualVal : string ;
  attributeIdVal : string;

  public AgeList :GroupSubAttribute[];
  public AnesTierList :GroupAttribute[];
  public AQIList:GroupAttribute[];
  public Quantum31List:GroupAttribute[];
  public MIPS44List:GroupAttribute[];
  public MIPS76List:GroupAttribute[];
  public MIPS404List:GroupAttribute[];
  public MIPS424List:GroupAttribute[];
  public MIPS430List:GroupAttribute[];
  public MIPS463List:GroupAttribute[];
  public MIPS477List:GroupAttribute[];
  public AnesTypeDocList: GroupSubAttribute[];
  public ASAStatus: GroupSubAttribute[];
  public ColonSurgery: GroupSubAttribute[];
  public RiskScore: GroupSubAttribute[];
  public Transferofcare: GroupSubAttribute[];
  public HandOffProtocol: GroupSubAttribute[];
  public AdultPONV : GroupSubAttribute[];
  public FieldID : string;
  signatureError: boolean = false;
  
  get hasDropDownError() {
    return (
      this.detailform.get('MAC701').touched &&
      this.detailform.get('MAC701').errors &&
      this.detailform.get('MAC701').errors.required
    )
  }

  isFieldValid(fieldName: string) {
    return (
      this.detailform.get(fieldName).valid || !this.detailform.get(fieldName).touched
    );
  }

  getErrorCSS(fieldName: string) {
    {
      return {
        'text-danger': !this.isFieldValid(fieldName)
      };
    }
  }

  onChangeASA(event){    
  }
  // onChangeAdultPONVPatient(event){
    
  //   if((this.detailform.controls["MAC704A"].value) || (this.detailform.controls["MAC704B"].value) || (this.detailform.controls["MAC704C"].value) || (this.detailform.controls["MAC704D"].value) || ( this.detailform.controls["MAC704E"].value))
  //   {
  //     this.hasErrorAdultPONVPatSpecific =false;
  //   }
  //   else{
  //     this.hasErrorAdultPONVPatSpecific =true;
  //   }
  //   if(this.detailform.controls["MAC704A"].value){
  //     this.detailform.get('MAC704B').disable();
  //     this.detailform.get('MAC704C').disable();
  //     this.detailform.get('MAC704D').disable();
  //     this.detailform.get('MAC704E').disable();
  //     this.detailform.get('MAC704A').enable();
  //   }
  //   if(this.detailform.controls["MAC704A"].value == false){
  //     this.detailform.get('MAC704B').enable();
  //     this.detailform.get('MAC704C').enable();
  //     this.detailform.get('MAC704D').enable();
  //     this.detailform.get('MAC704E').enable();
  //     this.detailform.get('MAC704A').enable();
  //   }
  //   if((this.detailform.controls["MAC704B"].value) || (this.detailform.controls["MAC704C"].value) || (this.detailform.controls["MAC704D"].value) || ( this.detailform.controls["MAC704E"].value)){
  //     this.detailform.get('MAC704A').disable();
  //     this.detailform.get('MAC704B').enable();
  //     this.detailform.get('MAC704C').enable();
  //     this.detailform.get('MAC704D').enable();
  //     this.detailform.get('MAC704E').enable();
  //   }

  // }

  onChangeCentralLine(event){   

      if(event.target.value === "0"){
        this.MAC022ADisabled = true;
        this.MAC022BDisabled = true;
        this.MAC022CDisabled = true;
        this.detailform.get('MAC022A').disable();
        this.detailform.get('MAC022B').disable();
        this.detailform.get('MAC022C').disable();
        this.detailform.get('MAC022B').reset();
        this.detailform.get('MAC022A').reset();
        this.detailform.get('MAC022C').reset();
        this.hasErrorsCentral = false;
      }
      else{
        this.MAC022ADisabled = false;
        this.MAC022BDisabled = false;
        this.MAC022CDisabled = false;
        this.detailform.get('MAC022A').enable();
        this.detailform.get('MAC022B').enable();
        this.detailform.get('MAC022C').enable();
        if(this.detailform.get('MAC022A').value || this.detailform.get('MAC022B').value || this.detailform.get('MAC022C').value)
        {this.hasErrorsCentral = false;}
        else{
        this.hasErrorsCentral = true;
        }
      }
  }

  onChangeMAC306(event){   
   
    if(event.target.value === "0"){
      this.MAC308Disabled = true;
      this.MAC307Disabled = true;
      this.detailform.get('MAC307').disable();
      this.detailform.get('MAC308').disable();
      this.detailform.get('MAC307').reset();
      this.detailform.get('MAC308').reset();
      this.hasErrorsMAC306 = false;
      this.hasErrorsMAC307 = false;
    }
    else{
      if(this.detailform.get('MAC306').value)
      {this.hasErrorsMAC306 = true;}
      else
      {this.hasErrorsMAC306 = false;}
      this.detailform.get('MAC307').enable();
      this.detailform.get('MAC308').enable();
      this.MAC308Disabled = false;
      this.MAC307Disabled = false;      
    }
  }

  onChangeMAC310(event){   

    if(event.target.value === "0"){
      this.MAC311Disabled = true;
      this.detailform.get('MAC311').disable();      
      this.detailform.get('MAC311').reset();      
      this.hasErrorsMAC310 = false;
      
    }
    else{
      if(this.detailform.get('MAC311').value)
      {this.hasErrorsMAC310 = false;}
      else
      {this.hasErrorsMAC310 = true;}
      this.detailform.get('MAC311').enable();   
      this.MAC311Disabled = false;   
      
    }
  }
  onChangeMAC311(event){ 
    this.hasErrorsMAC310 = false;     
    
  }

  onChangeMAC307(event){   

    if(event.target.value === "1"){  
      this.MAC308Disabled = true;    
      this.detailform.get('MAC308').disable();      
      this.detailform.get('MAC308').reset();
      this.hasErrorsMAC307 = false;
      this.hasErrorsMAC306 = false;
    }
    else{      
      this.detailform.get('MAC308').enable();
      this.MAC308Disabled = false;
      if(this.detailform.get('MAC307').value)
      {this.hasErrorsMAC307 = true;}
      else
      {this.hasErrorsMAC307 = false;}
      
      this.hasErrorsMAC306 = false;
    }
  }

    onChangeMAC308(event){  

      this.hasErrorsMAC307 = false;
    }
  
  onChangeCentralLineSub(event, str:string){
    this.hasErrorsCentral = false;
    this.detailform.get('MAC022B').reset();
    this.detailform.get('MAC022A').reset();
    this.detailform.get('MAC022C').reset();
    this.detailform.get(str).setValue('1');
   }

  

  onChangeElectiveSurgery(event){
    if(event.target.value === "0"){
       
      this.detailform.get('MAC015').disable();
      this.detailform.get('MAC015A').disable();
      this.detailform.get('MAC015B').disable();
     // this.detailform.get('MAC014').reset();
      this.detailform.get('MAC015').reset();
      this.detailform.get('MAC015A').reset();
      this.detailform.get('MAC015B').reset();
     // this.hasErrorsPtcurrentsmoker =  false;
      this.hasErrorPtreceivedprior = false;  
      this.hasErrorPtabstained = false;   
      this.hasErrorElectiveCase = false; 
      this.hasErrorPtabstainedDOS = false; 
      this.MAC015ADisabled =  true;
        this.MAC015Disabled =  true;
        this.MAC015BDisabled =  true;
    }
    else{
      this.MAC015ADisabled =  false;
      this.MAC015Disabled =  false;
      this.MAC015BDisabled =  false;
      this.detailform.get('MAC015').enable();
      this.detailform.get('MAC015A').enable();        
      this.detailform.get('MAC015B').enable();        
      if(!this.detailform.get('MAC015').value)
      {
       this.hasErrorPtreceivedprior =  true;
       //this.hasErrorElectiveCase = true;
      }  
      this.hasErrorElectiveCase = false;     
    }
  }

  onChangeCurrentSmoker(event){
   
      if(event.target.value === "0"){
        this.MAC015ADisabled =  true;
        this.MAC015Disabled =  true;
        this.MAC015BDisabled =  true;
        this.detailform.get('MAC201').disable();
        this.detailform.get('MAC015').disable();
        this.detailform.get('MAC015A').disable();
        this.detailform.get('MAC015B').disable();
        this.detailform.get('MAC015').reset();
        this.detailform.get('MAC015A').reset();
        this.detailform.get('MAC015B').reset();
        this.detailform.get('MAC201').reset();
      //  this.hasErrorPtreceivedprior = false;
      //  this.hasErrorsPtcurrentsmoker = false;
        this.hasErrorPtabstained = false;
        this.hasErrorPtabstainedDOS = false;
        this.hasErrorElectiveCase = false;
    }
    else{
        this.MAC015ADisabled =  false;
        this.MAC015Disabled =  false;
        this.MAC015BDisabled =  false;
        this.detailform.get('MAC201').enable();
        this.detailform.get('MAC015').enable();
        this.detailform.get('MAC015A').enable();
        this.detailform.get('MAC015B').enable();
        if(!this.detailform.get('MAC015').value)
        {
          
          //this.hasErrorPtreceivedprior = true;
          this.hasErrorElectiveCase = true;
        }
        this.hasErrorsPtcurrentsmoker = false;
      // this.hasErrorsPtcurrentsmoker = false
    }
  }


  

  onChangePriorInstruction(event){
    if(event.target.value === "0"){
      this.MAC015ADisabled = true;
      this.MAC015BDisabled = true;
      this.detailform.get('MAC015A').disable();
      this.detailform.get('MAC015A').reset();
      this.detailform.get('MAC015B').disable();
      this.detailform.get('MAC015B').reset();
      this.hasErrorPtreceivedprior = false;
      this.hasErrorPtabstained = false;
      this.hasErrorPtabstainedDOS = false; 
      this.hasErrorElectiveCase =  false; 
    }
    else{
        this.detailform.get('MAC015A').enable();
        this.MAC015ADisabled = false;
      
        this.hasErrorPtreceivedprior = false;
        if(!this.detailform.get('MAC015A').value)
        {
        this.hasErrorPtabstained = true;        
        }
        this.hasErrorElectiveCase =  false;
    }
  }
  onChangePtabstainedsmoking(event){
    if(event.target.value === "0"){
      this.MAC015BDisabled = true;
      this.detailform.get('MAC015B').disable();
      this.detailform.get('MAC015B').reset();
      this.hasErrorPtabstainedDOS = false;

    }
    else{
      this.detailform.get('MAC015B').enable();
      this.MAC015BDisabled = false;
      if(!this.detailform.get('MAC015B').value)
      {
        this.hasErrorPtabstainedDOS = true;
      }      
    }
    this.hasErrorPtabstained = false; 
}
onChangePtabstainedsmokingMAC015B(event){
  this.hasErrorPtabstained = false; 
  this.hasErrorPtabstainedDOS = false;
}  
onChangeAnesthesiaCase60(event){
    if(event.target.value === "0"){
        this.MAC032ADisabled =  true;
        this.MAC032BDisabled =  true;
        this.MAC032CDisabled =  true;
        this.detailform.get('MAC032A').disable();
        this.detailform.get('MAC032B').disable();
        this.detailform.get('MAC032C').disable();
        this.detailform.get('MAC032A').reset();
        this.detailform.get('MAC032B').reset();
        this.detailform.get('MAC032C').reset();
        this.hasErrorAnesCase60 = false;
    }
    else{
        this.MAC032ADisabled =  false;
        this.MAC032BDisabled =  false;
        this.MAC032CDisabled =  false;
        this.detailform.get('MAC032A').enable();
        this.detailform.get('MAC032B').enable();
        this.detailform.get('MAC032C').enable();
        if(this.detailform.get('MAC032A').value || this.detailform.get('MAC032B').value || this.detailform.get('MAC032C').value)
        {
          this.hasErrorAnesCase60 = false;
        }
        else{
          this.hasErrorAnesCase60 = true;
        }
    }
  }


  onChangeAnesthesiaCase60Sub(event,str:string){
      this.hasErrorAnesCase60 = false;
      this.detailform.get('MAC032A').reset();
        this.detailform.get('MAC032B').reset();
        this.detailform.get('MAC032C').reset();
        this.detailform.get(str).setValue('1'); 
  }

  onChange430PtReceivedInhal(event){
    if(event.target.value === "0"){
        this.MAC227Disabled =  true;
        this.MAC228Disabled =  true;
        this.MAC229Disabled =  true;
        this.MAC231Disabled =  true;

        this.detailform.get('MAC227').disable();
        this.detailform.get('MAC228').disable();
        this.detailform.get('MAC229').disable();
        this.detailform.get('MAC231').disable();
        this.detailform.get('MAC227').reset();
        this.detailform.get('MAC228').reset();
        this.detailform.get('MAC229').reset();
        this.detailform.get('MAC231').reset();
        this.hasErrorPatientreceivedinhal = false;
        this.hasErrorPatientexhibits3= false;
    }
    else{
        this.detailform.get('MAC227').enable();
        this.detailform.get('MAC228').enable();
        this.detailform.get('MAC229').enable();
        this.detailform.get('MAC231').enable();
        this.MAC227Disabled =  false;
        this.MAC228Disabled =  false;
        this.MAC229Disabled =  false;
        this.MAC231Disabled =  false;
        if(!this.detailform.get('MAC227').value)
        {
        this.hasErrorPatientreceivedinhal = true;
        }
    }
  }
  onChange430PtExhibitsPONV(event){
    if(event.target.value === "0"){
        this.MAC228Disabled =  true;
        this.MAC229Disabled =  true;
        this.MAC231Disabled =  true;
        this.detailform.get('MAC228').disable();
        this.detailform.get('MAC229').disable();
        this.detailform.get('MAC231').disable();
        this.detailform.get('MAC228').reset();
        this.detailform.get('MAC229').reset();
        this.detailform.get('MAC231').reset();
        this.hasErrorPatientreceivedinhal = false;
        this.hasErrorPatientexhibits3= false;
      }
     else{
      this.MAC228Disabled =  false;
      this.MAC229Disabled =  false;
      this.MAC231Disabled =  false;
        this.detailform.get('MAC228').enable();
        this.detailform.get('MAC229').enable();
        this.detailform.get('MAC231').enable();
        this.hasErrorPatientreceivedinhal = false;
        if(this.detailform.get('MAC228').value ||  this.detailform.get('MAC229').value || this.detailform.get('MAC231').value)
        { this.hasErrorPatientexhibits3= false;}
        else{
        this.hasErrorPatientexhibits3= true;
        }
     }
  }

  onChange463PtReceivedInhal(event){  
    if(event.target.value === "0"){
        this.MAC235Disabled = true;
        this.MAC237Disabled = true;
        this.MAC238Disabled = true;
        this.MAC239Disabled = true;
        this.detailform.get('MAC235').disable();
        this.detailform.get('MAC237').disable();
        this.detailform.get('MAC238').disable();
        this.detailform.get('MAC239').disable();
        this.detailform.get('MAC235').reset();
        this.detailform.get('MAC237').reset();
        this.detailform.get('MAC238').reset();
        this.detailform.get('MAC239').reset();
        this.hasErrorPatientexhibits2 = false;
        this.hasErrorPatientexhibits2Dtl= false;
    }
    else{
            if(this.detailform.get('MAC235').value==='0')
            {
              this.MAC237Disabled = true;
              this.MAC238Disabled = true;
              this.MAC239Disabled= true;
              this.detailform.get('MAC237').disable();
              this.detailform.get('MAC238').disable();
              this.detailform.get('MAC239').disable();
              this.detailform.get('MAC237').reset();
              this.detailform.get('MAC238').reset();
              this.detailform.get('MAC239').reset();
              this.hasErrorPatientexhibits2 = false;
              this.hasErrorPatientexhibits2Dtl = false;
            }
            else{
                this.MAC235Disabled = false;
                this.MAC237Disabled = false;
                this.MAC238Disabled = false;
                this.MAC239Disabled = false;
                this.detailform.get('MAC235').enable();
                this.detailform.get('MAC237').enable();
                this.detailform.get('MAC238').enable();
                this.detailform.get('MAC239').enable();
                if(!this.detailform.get('MAC235').value)
                {
                  this.hasErrorPatientexhibits2 = true;
                }
          }
    }    
  }

  onChange463PtExhibitsPONVSub(event,str:string){
    this.hasErrorPatientexhibits2Dtl = false;
    this.detailform.get('MAC237').reset();
    this.detailform.get('MAC238').reset();
    this.detailform.get('MAC239').reset();
    this.detailform.get(str).setValue('1');
  }

  

  onChange430PtExhibitsPONVSub(event,str:string){
    
    this.hasErrorPatientexhibits3 = false;
    this.detailform.get('MAC228').reset();
    this.detailform.get('MAC229').reset();
    this.detailform.get('MAC231').reset();
    this.detailform.get(str).setValue('1');
   

  }


  onChange463PtExhibitsPONV(event){
    if(event.target.value === "0") {
        this.MAC237Disabled = true;
        this.MAC238Disabled = true;
        this.MAC239Disabled= true;
        this.detailform.get('MAC237').disable();
        this.detailform.get('MAC238').disable();
        this.detailform.get('MAC239').disable();
        this.detailform.get('MAC237').reset();
        this.detailform.get('MAC238').reset();
        this.detailform.get('MAC239').reset();
        this.hasErrorPatientexhibits2 = false;
        this.hasErrorPatientexhibits2Dtl = false;
    }
    else{
      this.MAC237Disabled = false;
      this.MAC238Disabled = false;
      this.MAC239Disabled = false;
        this.detailform.get('MAC237').enable();
        this.detailform.get('MAC238').enable();
        this.detailform.get('MAC239').enable();
        this.hasErrorPatientexhibits2 = false;
        if(this.detailform.get('MAC237').value || this.detailform.get('MAC238').value || this.detailform.get('MAC239').value)
        {
          this.hasErrorPatientexhibits2Dtl = false;
        }
        else{
        this.hasErrorPatientexhibits2Dtl = true;
        }
    }
     
  }

  onChangeProcedure(event){
    if(event.target.value === "0") {
      this.MAC305Disabled =  true;
      this.MAC305ADisabled =  true;
      this.MAC305BDisabled =  true;
        this.detailform.get('MAC305').disable();
        this.detailform.get('MAC305A').disable();
        this.detailform.get('MAC305B').disable();
        this.detailform.get('MAC305').reset();
        this.detailform.get('MAC305A').reset();
        this.detailform.get('MAC305B').reset();
        // this.detailform.get('MAC301').disable();
        // this.detailform.get('MAC302').disable();
        // this.detailform.get('MAC303').disable();
        this.hasErrorAQIProcedure = false; 
        this.hasErrorAQIPtreceived  =  false;  
        this.hasErrorAQI304 = false;

    }
    else{
      if(this.detailform.get('MAC305').value)
      {
        this.hasErrorAQIProcedure = false; 
      }
      else
      {
        this.hasErrorAQIProcedure = true; 
      }
        this.MAC305Disabled =  false;
        this.MAC305ADisabled =  false;
        this.MAC305BDisabled =  false;
        this.detailform.get('MAC305').enable();
        this.detailform.get('MAC305A').enable();
        this.detailform.get('MAC305B').enable();
        // this.detailform.get('MAC301').disable();
        // this.detailform.get('MAC302').disable();
        // this.detailform.get('MAC303').disable();
        this.hasErrorAQI304 = false;
    }
  }

  onChangeAQIantibiotic(event,str:string){
    this.hasErrorAQIPtreceived = false;
    
    if(str ==="MAC305A")
    {
      if(this.detailform.get('MAC305B').value ==="1" || this.detailform.get('MAC305B').value ==="0")
      {
        this.detailform.get('MAC305B').reset();
      }
    }
    else if(str ==="MAC305B")
    {
      if(this.detailform.get('MAC305A').value ==="1" || this.detailform.get('MAC305A').value ==="0")
      {
        this.detailform.get('MAC305A').reset();
      }
    }
  }

  // onChangeAntibioticYes(event,str:string){
  //   this.hasErrorAQIPtreceived = false;
  //   if(this.detailform.get('MAC237').reset(); )
  //   this.detailform.get('MAC237').reset();
  //   this.detailform.get('MAC238').reset();
  //   this.detailform.get('MAC239').reset();
  //   this.detailform.get(str).setValue('1');
  // }


  onChangeAQIPtreceived(event){
    if(event.target.value === "0") {
        //this.detailform.get('MAC305').disable();
        this.MAC305ADisabled = true;
        this.MAC305BDisabled = true;
        this.detailform.get('MAC305A').disable();
        this.detailform.get('MAC305B').disable();
        //this.detailform.get('MAC305').reset();
        this.detailform.get('MAC305A').reset();
        this.detailform.get('MAC305B').reset();
        this.hasErrorAQIProcedure = false; 
        this.hasErrorAQIPtreceived = false;
        
    }
    else{
        this.hasErrorAQIProcedure = false;
        //this.detailform.get('MAC305').enable();
        this.detailform.get('MAC305A').enable();
        this.detailform.get('MAC305B').enable();
        this.MAC305ADisabled = false;
        this.MAC305BDisabled = false;

        if(this.detailform.get('MAC305A').value || this.detailform.get('MAC305B').value)
        {
          this.hasErrorAQIPtreceived = false;
        }
        else
        {
        this.hasErrorAQIPtreceived = true;
        }
    }
  }
  onChange477OpenLap(event){
    if(event.target.value === "0") {
      this.MAC222ADisabled =  true;
      this.MAC222BDisabled =  true;
      this.detailform.get('MAC222A').disable();
      this.detailform.get('MAC222B').disable();
      this.detailform.get('MAC222A').reset();
      this.detailform.get('MAC222B').reset();
      this.hasErrorOpenLog = false;
      this.hasErrorNonemergentcaseYes = false;  
    } else{
      this.detailform.get('MAC222A').enable();
      this.detailform.get('MAC222B').enable();
      this.MAC222ADisabled =  false;
      this.MAC222BDisabled =  false;
      if (this.detailform.get('MAC222A').value || this.detailform.get('MAC222B').value)
      {
        this.hasErrorNonemergentcaseYes = false;
      } else {
        this.hasErrorNonemergentcaseYes = true;
      }
      this.hasErrorOpenLog = false;
    }

  }
  onChange477MultiPainMgmt(event){
    if(event.target.value === "1") {
      this.MAC222ADisabled= true;
      this.MAC222Disabled= true;
      this.MAC222BDisabled= true;
        this.detailform.get('MAC222').disable();
        this.detailform.get('MAC222A').disable();
        this.detailform.get('MAC222B').disable();
        this.detailform.get('MAC222').reset();
        this.detailform.get('MAC222A').reset();
        this.detailform.get('MAC222B').reset();
        this.hasErrorOpenLog = false;
        this.hasErrorNonemergentcaseYes = false;  
        this.hasErrorNonemergentcase = false;      
    }
    else{
      this.MAC222ADisabled= false;
      this.MAC222Disabled= false;
      this.MAC222BDisabled= false;
        this.detailform.get('MAC222').enable();
        this.detailform.get('MAC222A').enable();
        this.detailform.get('MAC222B').enable();
        if (this.detailform.get('MAC222').value)
        {
          this.hasErrorOpenLog = false;
        //this.hasErrorNonemergentcaseYes = false;  
        }else {
          //this.hasErrorNonemergentcaseYes = true; 
          this.hasErrorOpenLog = true; 
        }

        this.hasErrorNonemergentcase = false;  
                  
    }
  }
  onChange477MultiPainMgmtSub(event, str:string){
    this.hasErrorNonemergentcaseYes = false; 
    this.detailform.get('MAC222A').reset();
    this.detailform.get('MAC222B').reset();
    // this.detailform.get('MAC224').reset()  ; 
    this.detailform.get(str).setValue('1'); 
  }

  onChangeAQI(event, str:string){
    if((this.detailform.controls["MAC301"].value) || (this.detailform.controls["MAC302"].value) || (this.detailform.controls["MAC303"].value))
    {
      this.MAC304Disabled =  true;
      this.MAC305Disabled =  true;
      this.MAC305ADisabled =  true;
      this.MAC305BDisabled =  true;
      this.detailform.get('MAC304').disable();
      this.detailform.get('MAC305').disable();
      this.detailform.get('MAC305A').disable();
      this.detailform.get('MAC305B').disable();
      this.detailform.get('MAC304').reset();
      this.detailform.get('MAC305').reset();
      this.detailform.get('MAC305A').reset();
      this.detailform.get('MAC305B').reset();
      this.hasErrorAQI304 = false;
      this.hasErrorAQI = false;
      this.hasErrorAQIPtreceived = false;
      this.hasErrorAQIProcedure = false;
    }
    else
    {
      this.MAC304Disabled =  false;
      this.MAC305Disabled =  false;
      this.MAC305ADisabled =  false;
      this.MAC305BDisabled =  false;
      this.hasErrorAQI304 = true;
      this.detailform.get('MAC304').enable();
      this.detailform.get('MAC305').enable();
      this.detailform.get('MAC305A').enable();
      this.detailform.get('MAC305B').enable();

    }
  }

  onChangeMIPS44(str:string){
    this.hasErrors = false;
    this.detailform.get('MAC216').reset();
    this.detailform.get('MAC217').reset();
    this.detailform.get('MAC218').reset(); 
    this.detailform.get('MAC219').reset(); 
    this.detailform.get(str).setValue('1');     

  }
  MIPS44ResetValues(){
    this.detailform.get('MAC216').reset();
    this.detailform.get('MAC217').reset();
    this.detailform.get('MAC218').reset(); 
    this.detailform.get('MAC219').reset(); 
  }
  MIPS404ResetValues(){
   this.detailform.get('MAC017').reset();
    this.detailform.get('MAC014').reset();
    this.detailform.get('MAC015').reset();
    this.detailform.get('MAC015A').reset();
    this.detailform.get('MAC015B').reset();
  }
  AQIResetValues(){
    this.detailform.get('MAC312').reset();
    this.detailform.get('MAC301').reset();
    this.detailform.get('MAC302').reset();
    this.detailform.get('MAC303').reset();
    this.detailform.get('MAC304').reset();
    this.detailform.get('MAC305').reset();
    this.detailform.get('MAC305A').reset();
    this.detailform.get('MAC305B').reset();
  }
  MIPS430ResetValues(){
    this.detailform.get('MAC233').reset();
    this.detailform.get('MAC226').reset();
    this.detailform.get('MAC227').reset();
    this.detailform.get('MAC228').reset();
    this.detailform.get('MAC229').reset();
    this.detailform.get('MAC231').reset();
  }
  MIPS76ResetValues(){
    this.detailform.get('MAC022').reset();
    this.detailform.get('MAC024').reset();
    this.detailform.get('MAC022A').reset();
    this.detailform.get('MAC022B').reset();
    this.detailform.get('MAC022C').reset();
 }
 MIPS424ResetValues(){
   this.detailform.get('MAC035').reset();
   this.detailform.get('MAC032').reset();
   this.detailform.get('MAC032A').reset();
   this.detailform.get('MAC032B').reset();
   this.detailform.get('MAC032C').reset();
 }
 QuantuamResetValues(){
    this.detailform.get('MAC313').reset();
    this.detailform.get('MAC306').reset();
    this.detailform.get('MAC307').reset();
    this.detailform.get('MAC308').reset();
 }
 MIPS463ResetValues(){
    this.detailform.get('MAC240').reset();
    this.detailform.get('MAC232').reset();
    this.detailform.get('MAC235').reset();
    this.detailform.get('MAC237').reset();
    this.detailform.get('MAC238').reset();
    this.detailform.get('MAC239').reset();
 }
 MIPS477ResetValues(){
    this.detailform.get('MAC223').reset();
    this.detailform.get('MAC221').reset();
    this.detailform.get('MAC222').reset();
    this.detailform.get('MAC222A').reset();
    this.detailform.get('MAC222B').reset();
 }
 ANESTierResetValues(){
  this.detailform.get('MAC314').reset();
  this.detailform.get('MAC310').reset();
  this.detailform.get('MAC311').reset();
 }
 AgeLoadMeasures(str:string,Call:string){

        
      this.detailform.get('MAC709A').reset(); // Reset Age
      this.detailform.get('MAC709B').reset();
      this.detailform.get('MAC709C').reset();     
      this.detailform.get(str).setValue('1'); 

      if(str=='MAC709A'){

        

        this.onChangeMIPS44('MAC216'); // set and disable all 44 elemnts
        this.detailform.get('MAC017').setValue('1'); //set 404 NA to true
        this.onChangeMIPS404NA(true); // disable all 404 elements
        this.detailform.get('MAC312').setValue('1');  //set AQI NA to true  
        this.onChangeMIPSAQINA(true); // disable all AQI elements
        this.detailform.get('MAC233').setValue('1'); // set 430 NA to true    
        this.onChangeMIPS430NA(true); // disable all 430 elements
        this.detailform.get('MAC240').setValue('1'); // set 463 NA to true      
        this.onChangeMIPS463NA(true); // disable all 463 elements
        this.detailform.get('MAC223').setValue('1'); // set 477 NA to true      
        this.onChangeMIPS477NA(true); // disable all 477 elements
        this.detailform.get('MAC314').setValue('1'); // set 1.x NA to true       
        this.onChangeAnesTier(true); // disable all 1.x elements

        if((Call==='I') || (!(this.DBAge===str))){
             //For Applicable MIPS reset values
            this.MIPS76ResetValues();
            this.QuantuamResetValues();
            //if Anes Type is Peripharal or MAC dont reset MIPS 424
            if(!(this.detailform.get('MAC701').value =='MAC701D') && !(this.detailform.get('MAC701').value =='MAC701E')){
              this.MIPS424ResetValues();
            }
           
        }
      } else if(str=='MAC709B'){

        this.onChangeMIPS44('MAC216'); // set and disable all 44 elemnts
        this.detailform.get('MAC017').setValue('1'); //set 404 NA to true
        this.onChangeMIPS404NA(true); // disable all 404 elements
        this.detailform.get('MAC312').setValue('1');  //set AQI NA to true  
        this.onChangeMIPSAQINA(true); // disable all AQI elements
        this.detailform.get('MAC233').setValue('1'); // set 430 NA to true    
        this.onChangeMIPS430NA(true); // disable all 430 elements
        this.detailform.get('MAC223').setValue('1'); // set 477 NA to true      
        this.onChangeMIPS477NA(true); // disable all 477 elements
        this.detailform.get('MAC314').setValue('1'); // set 1.x NA to true       
        this.onChangeAnesTier(true); // disable all 1.x elements

        if((Call==='I') || (!(this.DBAge===str))){
             //For Applicable MIPS reset values
            this.MIPS76ResetValues();
            this.QuantuamResetValues();
            this.MIPS463ResetValues();
            //if Anes Type is Peripharal or MAC dont reset MIPS 424
            if(!(this.detailform.get('MAC701').value =='MAC701D') && !(this.detailform.get('MAC701').value =='MAC701E')){
              this.MIPS424ResetValues();
            }
        }
      } else if(str=='MAC709C'){
          this.detailform.get('MAC240').setValue('1'); // set 463 NA to true      
          this.onChangeMIPS463NA(true); // disable all 463 elements

          if((Call==='I') || (!(this.DBAge===str))){

                if(!(this.detailform.get('MAC703').value ==='1')) //Not reset MIPS 404 & 477 if Emergency to YES
                {              
                  this.MIPS404ResetValues();
                  this.MIPS477ResetValues();
                }
                 //For Applicable MIPS reset values
                this.MIPS44ResetValues();
                this.MIPS76ResetValues();
                this.QuantuamResetValues();
                
                this.AQIResetValues();
                this.MIPS430ResetValues();
                
                this.ANESTierResetValues();
                 //if Anes Type is Peripharal or MAC dont reset MIPS 424
                 if(!(this.detailform.get('MAC701').value =='MAC701D') && !(this.detailform.get('MAC701').value =='MAC701E')){
                  this.MIPS424ResetValues();
                }
          }
         
      }
 }
  // AgeLoadMeasures123(str:string,Call:string){
  //   //this.hasErrors = false;
    
  //   this.detailform.get('MAC709A').reset();
  //   this.detailform.get('MAC709B').reset();
  //   this.detailform.get('MAC709C').reset();     
  //   this.detailform.get(str).setValue('1'); 

  //   //Clear NotApplicable
  //   this.detailform.get('MAC216').reset();
  //   this.detailform.get('MAC024').reset();
  //   this.detailform.get('MAC035').reset();
  //   this.detailform.get('MAC312').reset();
  //   this.detailform.get('MAC233').reset();
  //   this.detailform.get('MAC240').reset();
  //   this.detailform.get('MAC313').reset();
  //   this.detailform.get('MAC314').reset();
    
  //   if((Call==='I') || (!(this.DBAge===this.CaseRecAge)))
  //   {
  //    //Clear 76
  //    this.detailform.get('MAC022').reset();
  //    this.detailform.get('MAC024').reset();
  //    this.detailform.get('MAC022A').reset();
  //    this.detailform.get('MAC022B').reset();
  //    this.detailform.get('MAC022C').reset();

  //    //424
  //    this.detailform.get('MAC035').reset();
  //    this.detailform.get('MAC032').reset();
  //    this.detailform.get('MAC032A').reset();
  //    this.detailform.get('MAC032B').reset();
  //    this.detailform.get('MAC032C').reset();

  //    //Quan
  //    this.detailform.get('MAC313').reset();
  //    this.detailform.get('MAC306').reset();
  //    this.detailform.get('MAC307').reset();
  //    this.detailform.get('MAC308').reset();
  //   }    

  //   ////

  //   this.detailform.get('MAC216').reset();
  //  // this.detailform.get('MAC017').reset();
  //   this.detailform.get('MAC312').reset();
  //   this.detailform.get('MAC233').reset();
  //   this.detailform.get('MAC240').reset();
  //   //this.detailform.get('MAC223').reset();
  //   this.detailform.get('MAC314').reset();

  //   if(!(this.detailform.get('MAC703').value ==='1'))
  //   {
  //     this.detailform.get('MAC017').reset();
  //     this.detailform.get('MAC223').reset();
  //   }
    


  //   if(str=='MAC709A')    
  //   {
  //     this.onChangeMIPS44('MAC216');
  //     this.detailform.get('MAC017').setValue('1'); 
  //     this.onChangeMIPS404NA(true);
  //     this.detailform.get('MAC312').setValue('1');       
  //     this.onChangeMIPSAQINA(true);
  //     this.detailform.get('MAC233').setValue('1');       
  //     this.onChangeMIPS430NA(true);
  //     this.detailform.get('MAC240').setValue('1');       
  //     this.onChangeMIPS463NA(true);
  //     this.detailform.get('MAC223').setValue('1');       
  //     this.onChangeMIPS477NA(true);
  //     this.detailform.get('MAC314').setValue('1');       
  //     this.onChangeAnesTier(true);
  //   }
  //   else if(str=='MAC709B')
  //   {
  //     //Clear 463
  //     if((Call==='I') || (!(this.DBAge===this.CaseRecAge)))
  //     {
  //     this.detailform.get('MAC240').reset();
  //     this.detailform.get('MAC232').reset();
  //     this.detailform.get('MAC235').reset();
  //     this.detailform.get('MAC237').reset();
  //     this.detailform.get('MAC238').reset();
  //     this.detailform.get('MAC239').reset();
  //    }

  //     this.onChangeMIPS44('MAC216');
  //     this.detailform.get('MAC017').setValue('1'); 
  //     this.onChangeMIPS404NA(true);
  //     this.detailform.get('MAC312').setValue('1');       
  //     this.onChangeMIPSAQINA(true);
  //     this.detailform.get('MAC233').setValue('1');       
  //     this.onChangeMIPS430NA(true);      
  //     this.detailform.get('MAC223').setValue('1');       
  //     this.onChangeMIPS477NA(true);
  //     this.detailform.get('MAC314').setValue('1');       
  //     this.onChangeAnesTier(true);
  //   }
  //   else if(str=='MAC709C')
  //   {

     
  //     if((Call==='I') || (!(this.DBAge===this.CaseRecAge)))
  //     {
  //     //Clear MIPS 44
  //     this.detailform.get('MAC216').reset();
  //     this.detailform.get('MAC217').reset();
  //     this.detailform.get('MAC218').reset(); 
  //     this.detailform.get('MAC219').reset();

  //     // 404       
  //     this.detailform.get('MAC014').reset();
  //     this.detailform.get('MAC015').reset();
  //     this.detailform.get('MAC015A').reset();
  //     this.detailform.get('MAC015B').reset();
  //     this.detailform.get('MAC201').reset();

  //     //AQI
  //     this.detailform.get('MAC312').reset();
  //     this.detailform.get('MAC301').reset();
  //     this.detailform.get('MAC302').reset();
  //     this.detailform.get('MAC303').reset();
  //     this.detailform.get('MAC304').reset();
  //     this.detailform.get('MAC305').reset();
  //     this.detailform.get('MAC305A').reset();
  //     this.detailform.get('MAC305B').reset();

  //     //430
      
  //     this.detailform.get('MAC233').reset();
  //     this.detailform.get('MAC226').reset();
  //     this.detailform.get('MAC227').reset();
  //     this.detailform.get('MAC228').reset();
  //     this.detailform.get('MAC229').reset();
  //     this.detailform.get('MAC231').reset();

  //     //477
  //     this.detailform.get('MAC221').reset();
  //     this.detailform.get('MAC222').reset();
  //     this.detailform.get('MAC222A').reset();
  //     this.detailform.get('MAC222B').reset();

  //     //Anes Tier      
  //     this.detailform.get('MAC314').reset();
  //     this.detailform.get('MAC310').reset();
  //     this.detailform.get('MAC311').reset();

  //     }
      
  //     this.detailform.get('MAC240').setValue('1');       
  //     this.onChangeMIPS463NA(true);

  //   }

  // }

  changeDate() {
    this.name = sessionStorage.getItem('loggedInUser');
    this.date = new Date().toLocaleString();
    this.hasErrorsSignature = false;
    this.detailform.get('BtnSign').disable();
    this.signatureError = false;
  }   

  toggleMACRAForm(event){
    if(event.target.value === "0")
    {
      this.isDisplay=  true ;
      this.isSignatureDisplay = true;
      this.isANESQualitySectionDisplay = true;
    }
    else{
      this.isDisplay=  false ;
      this.isSignatureDisplay = false;
      this.isANESQualitySectionDisplay = false;
    }
    
  }

  public show:boolean = false;
  public checked:boolean = false;
  detailform: FormGroup;  
  public arrList: attribute;
  public arr: attribute[];
  
  private getName(control: AbstractControl): string | null {
    let group = <FormGroup>control.parent;
    if (!group) {
      return null;
    }
    let name: string;
    Object.keys(group.controls).forEach(key => {
      let childControl = group.get(key);
      if (childControl !== control) {
        return;
      }
      name = key;
    });
    return name;
  }
  
  SubmitMacra() : attribute[]
  {    
    
try  
{     
  this.isErrorAlertAPICall = false;
  this.ErrorMessageAPICall = "";
    
    var a = [
      {id:"MAC216"},
      {id:"MAC217"},
      {id:"MAC218"},
      {id:"MAC219"},
      {id:"MAC201"},
      {id:"MAC202"},
      {id:"MAC014"},
      {id:"MAC015A"},
      {id:"MAC015B"},
      {id:"MAC022"},
      {id:"MAC023"},
      {id:"MAC022A"},
      {id:"MAC022B"},
      {id:"MAC022C"},
      {id:"MAC032"},
      {id:"MAC032A"},
      {id:"MAC032B"},
      {id:"MAC032C"},
      {id:"MAC221"},
      {id:"MAC222B"},
      {id:"MAC222"},
      {id:"MAC222A"},
      {id:"MAC232"},
      {id:"MAC235"},
      {id:"MAC237"},
      {id:"MAC239"},
      {id:"MAC238"},
      {id:"MAC226"},
      {id:"MAC227"},
      {id:"MAC228"},
      {id:"MAC231"},
      {id:"MAC229"},
      {id:"MAC701"},
      {id:"MAC702"},
      {id:"MAC708"},
      {id:"MAC703"},
      {id:"MAC301"},
      {id:"MAC302"},
      {id:"MAC303"},
      {id:"MAC304"},
      {id:"MAC305"},
      {id:"MAC305A"},
      {id:"MAC305B"},
      {id:"MAC306"},
      {id:"MAC307"},
      {id:"MAC308"},
      {id:"MAC309"},
      {id:"MAC310"},
      {id:"MAC311"},
      {id:"MAC017"},
      {id:"MAC024"},
      {id:"MAC035"},
      {id:"MAC223"},
      {id:"MAC233"},
      {id:"MAC240"},
      {id:"MAC312"},
      {id:"MAC313"},
      {id:"MAC314"},
      {id:"MAC015"},
      {id:"MAC701A"},
      {id:"MAC701B"},
      {id:"MAC701C"},
      {id:"MAC701D"},
      {id:"MAC702A"},
      {id:"MAC702B"},
      {id:"MAC702C"},
      {id:"MAC702D"},
      {id:"MAC702E"},
      {id:"MAC702F"},
      {id:"MAC702G"},
      {id:"MAC708A"},
      {id:"MAC708B"},
      {id:"MAC708C"},
      {id:"MAC311A"},
      {id:"MAC311B"},
      {id:"MAC311C"},
      {id:"MAC311D"},
      {id:"MAC701E"},
      {id:"MAC701F"},
      {id:"MAC701G"},
      {id:"MAC310A"},
      {id:"MAC310B"},
      {id:"MAC310C"},
      {id:"MAC310D"},
      {id:"MAC310E"},
      {id:"MAC709A"},
      {id:"MAC709B"},
      {id:"MAC709C"}


   ];        
            this.arr= [];     
            for (const field in this.detailform.controls) { // 'field' is a string  
            this.FieldID  = this.getName(this.detailform.controls[field]);  
            //if ((this.FieldID==="MAC701") || (this.FieldID==="MAC702") || (this.FieldID==="MAC708") || (this.FieldID==="MAC705") || (this.FieldID==="MAC706") || (this.FieldID==="MAC707") )
            if ((this.FieldID==="MAC701") || (this.FieldID==="MAC702") ||(this.FieldID==="MAC705") || (this.FieldID==="MAC706") || (this.FieldID==="MAC707") )
            {
              this.attributeIdVal = this.detailform.controls[field].value;
              this.ActualVal = "1";
            }
            else{
              this.attributeIdVal = this.getName(this.detailform.controls[field]);
              
              if(this.detailform.controls[field].value==true)
              {
                this.ActualVal = "1";
              }
              else
              {
                this.ActualVal = this.detailform.controls[field].value;
              } 
            }
                        
            const objTmp: attribute = {
              
              attributeId: this.attributeIdVal,
              attributeValue: this.ActualVal,
              attributeAdditionalNotes:this.AdditionalNote,
              attributeType: "MACRA"   
            };
           
                if((objTmp.attributeValue) && !(objTmp.attributeValue==="Add Signature"))
                {
                 
                  var isPresent = a.some(function(el){ return el.id === objTmp.attributeId});
                  if(!(this.detailform.get('MAC700').value ==="0" && isPresent===true))
                  {
                    //if(objTmp.attributeId !== 'MAC702F'){
                     
                        if((objTmp.attributeId) && (objTmp.attributeValue))
                        {
                          
                          this.arr.push(objTmp);
                        }
                    //}
                   
                  }
                }
            }

            // Add Name & date..
            if ((this.detailform.get('MAC700').value ==="1") && (this.name))
            {
              const objTmpName: attribute =  {              
                attributeId: "MAC033",
                attributeValue: this.name,
                attributeAdditionalNotes:"",
                attributeType: "MACRA"   
              };
              this.arr.push(objTmpName);
            }
            if ((this.detailform.get('MAC700').value ==="1") && (this.date))
            {
              const objTmpDt: attribute = {
              
                attributeId: "MAC034",
                attributeValue: this.date,
                attributeAdditionalNotes:"",
                attributeType: "MACRA"   
              };
              this.arr.push(objTmpDt);
            }

          //console.log(this.arr);
    return this.arr
  }  
  catch(error)  
  { this.logger.log("Error raised in MACRA.component.ts - SubmitMacra :ERR::" + error.message);                           
    //this.isErrorAlertAPICall = true;
    //this.ErrorMessageAPICall = Globalconstants.ErrorMessageUI; 
  } 
  }

  

  constructor(private fb: FormBuilder,private apiService: MulesoftAPIServiceService, private el: ElementRef, @Inject(TabsComponent) private parent: TabsComponent,private logger: LogService) {

    this.isErrorAlertAPICall = false;
    //console.log(this.monthDiff());

    this.detailform = this.fb.group({
      //MIPS44: ['', Validators.required],
      MAC033: ['',Validators.required] , 
      MAC034: [''] , 
      MAC700: [''] , 
      MAC216: ['',Validators.required] , 
      MAC217: ['',Validators.required] , 
      MAC218: ['',Validators.required] , 
      MAC219: ['',Validators.required] , 
      MAC201: [''] , 
    //  MAC202: ['',Validators.required] , 
      MAC014: ['',Validators.required] , 
      MAC015: [''] , 
      MAC015A: [''] , 
      MAC015B: [''] ,
      MAC022: ['', Validators.required] , 
    //  MAC023: ['', Validators.required] , 
      MAC022A: ['',Validators.required] , 
      MAC022B: ['',Validators.required] , 
      MAC022C: ['',Validators.required] , 
      MAC032: ['',Validators.required] , 
      MAC032A: ['',Validators.required] , 
      MAC032B: ['',Validators.required] , 
      MAC032C: ['',Validators.required] , 
      MAC221: ['',Validators.required] , 
      MAC222B: ['',Validators.required] , 
      MAC222: ['',Validators.required] , 
      MAC222A: ['',Validators.required] , 
      MAC232: ['',Validators.required] , 
      MAC235: ['',Validators.required] , 
      MAC237: ['',Validators.required] , 
      MAC239: ['',Validators.required] , 
      MAC238: ['',Validators.required] , 
      MAC226: ['',Validators.required] , 
      MAC227: ['',Validators.required] , 
      MAC228: ['',Validators.required] , 
      MAC231: ['',Validators.required] , 
      MAC229: ['',Validators.required] , 
      MAC701: ['', Validators.required] , 
      MAC702: ['', Validators.required] , 
    //  MAC708: ['', Validators.required] , 
      MAC704: [''] , 
    //  MAC705: [''] , 
      MAC706: [''] ,
      MAC703: ['', Validators.required] , 
      // MAC704A: ['', Validators.required] ,
      // MAC704B: ['', Validators.required] ,
      // MAC704C: ['', Validators.required] ,
      // MAC704D: ['', Validators.required] ,
      // MAC704E: ['', Validators.required],
      MAC707 : [''],
      BtnSign: ['Add Signature'],
      MAC301 : ['', Validators.required],
      MAC302 : ['', Validators.required],
      MAC303 : ['', Validators.required],
      MAC304 : ['', Validators.required],
      MAC305 : [''],
      MAC305A : [''],
      MAC305B : [''],
      MAC306 : ['', Validators.required],
      MAC307 : [''],
      MAC308 : [''] ,
      MAC310: ['', Validators.required],
      MAC311  : ['', Validators.required],
      MAC024: [''],
      MAC035:[''],
      MAC017:[''],
      MAC312:[''],
      MAC233:[''],
      MAC240:[''],
      MAC223:[''],
      MAC313:[''],
      MAC314:[''],
      MAC709A:[''],
      MAC709B:[''],
      MAC709C:['']
    });  
    
    
    //Bind dropdown values from GetformElements API
    this.apiService.getFormValues().subscribe(
      result => { 
          if(result.form[0].Macra.groups[0][Globalconstants.AnesTypeDocListKey].groupAttribute[1].groupAttributeId === "MAC701"){
            this.AnesTypeDocList  = result.form[0].Macra.groups[0][Globalconstants.AnesTypeDocListKey].groupAttribute[1].groupSubAttribute;//.sort((a,b)=> a.group - b.groupAttributeSubItem); ; //Anes type            
          }

          if(result.form[0].Macra.groups[0][Globalconstants.AnesTypeDocListKey].groupAttribute[2].groupAttributeId === "MAC702"){
            this.ASAStatus = result.form[0].Macra.groups[0][Globalconstants.AnesTypeDocListKey].groupAttribute[2].groupSubAttribute; 
          }
          if(result.form[0].Macra.groups[0][Globalconstants.AnesTypeDocListKey].groupAttribute[3].groupAttributeId === "MAC703"){
            this.ColonSurgery = result.form[0].Macra.groups[0][Globalconstants.AnesTypeDocListKey].groupAttribute[3].groupSubAttribute; 
          }

          if(result.form[0].Macra.groups[0][Globalconstants.AnesTypeDocListKey].groupAttribute[4].groupAttributeId === "MAC709"){
            this.AgeList = result.form[0].Macra.groups[0][Globalconstants.AnesTypeDocListKey].groupAttribute[4].groupSubAttribute; 
          }
         // this.RiskScore = result.form[0].Macra.groups[0][Globalconstants.RiskScoreKey].groupAttribute[0].groupSubAttribute; 
          if(result.form[0].Macra.groups[0][Globalconstants.RiskScoreKey].groupAttribute[0].groupAttributeId === "MAC706"){
            this.Transferofcare = result.form[0].Macra.groups[0][Globalconstants.RiskScoreKey].groupAttribute[0].groupSubAttribute; 
          }
          if(result.form[0].Macra.groups[0][Globalconstants.RiskScoreKey].groupAttribute[1].groupAttributeId === "MAC707"){
            this.HandOffProtocol = result.form[0].Macra.groups[0][Globalconstants.RiskScoreKey].groupAttribute[1].groupSubAttribute; 
          }

          // if(result.form[0].Macra.groups[0][Globalconstants.AgeKey].groupAttribute[0].groupAttributeId === "MAC709"){
          //   this.AgeList = result.form[0].Macra.groups[0][Globalconstants.AgeKey].groupAttribute[0].groupSubAttribute; 
          // }
          
         // console.log(this.AgeList);
         // this.AdultPONV = result.form[0].Macra.groups[0][Globalconstants.RiskScoreKey].groupAttribute[3].groupSubAttribute;          
          sessionStorage.removeItem('AnesTypeDocList'); 
          sessionStorage.removeItem('ASAStatus'); 
          sessionStorage.removeItem('ColonSurgery'); 
          //sessionStorage.removeItem('RiskScore'); 
          sessionStorage.removeItem('Transferofcare'); 
          sessionStorage.removeItem('HandOffProtocol'); 
          sessionStorage.setItem('AnesTypeDocList', JSON.stringify(this.AnesTypeDocList));
          sessionStorage.setItem('ASAStatus', JSON.stringify(this.ASAStatus));
          sessionStorage.setItem('ColonSurgery', JSON.stringify(this.ColonSurgery));
          //sessionStorage.setItem('RiskScore', JSON.stringify(this.RiskScore));
          sessionStorage.setItem('Transferofcare', JSON.stringify(this.Transferofcare));
          sessionStorage.setItem('HandOffProtocol', JSON.stringify(this.HandOffProtocol));

          this.AQIList =  result.form[0].Macra.groups[0][Globalconstants.AQIListKey].groupAttribute.sort((a,b)=> a.groupAttributeOrder - b.groupAttributeOrder);                             
          this.AnesTierList =  result.form[0].Macra.groups[0][Globalconstants.AnesTierListKey].groupAttribute.sort((a,b)=> a.groupAttributeOrder - b.groupAttributeOrder);                   
          this.Quantum31List =  result.form[0].Macra.groups[0][Globalconstants.Quantum31ListKey].groupAttribute.sort((a,b)=> a.groupAttributeOrder - b.groupAttributeOrder);                   
          this.MIPS44List =  result.form[0].Macra.groups[0][Globalconstants.MIPS44ListKey].groupAttribute;                   
          this.MIPS76List =  result.form[0].Macra.groups[0][Globalconstants.MIPS76ListKey].groupAttribute;                   
          this.MIPS76List.sort(function (a, b) {
              return a.groupAttributeOrder - b.groupAttributeOrder;
            });
                    
          this.MIPS404List =  result.form[0].Macra.groups[0][Globalconstants.MIPS404ListKey].groupAttribute;                   
          this.MIPS404List.sort(function (a, b) {
            return a.groupAttributeOrder - b.groupAttributeOrder;
          });
          

          this.MIPS424List = result.form[0].Macra.groups[0][Globalconstants.MIPS424ListKey].groupAttribute;                   
          this.MIPS424List.sort(function (a, b) {
            return a.groupAttributeOrder - b.groupAttributeOrder;
          });

           this.MIPS430List = result.form[0].Macra.groups[0][Globalconstants.MIPS430ListKey].groupAttribute;                   
           this.MIPS430List.sort(function (a, b) {
            return a.groupAttributeOrder - b.groupAttributeOrder;
          });

            //console.log(this.MIPS430List);

           this.MIPS463List = result.form[0].Macra.groups[0][Globalconstants.MIPS463ListKey].groupAttribute;                   
           this.MIPS463List.sort(function (a, b) {
            return a.groupAttributeOrder - b.groupAttributeOrder;
          });

          if(this.MIPS463List)
          {
              for (let i = 0; i < this.MIPS463List.length; i++) {
                let MIPS463Lisdata = this.MIPS463List.find(ob => ob['groupAttributeId'] === 'MAC232');                        
                  if(MIPS463Lisdata){       
                  
                  var splitted = MIPS463Lisdata.groupAttributeType.split(",", 2);                   
                  this.MAC232Text1 = splitted[0].toString().replace("Radio (","");
                  this.MAC232Text2 = splitted[1].toString().replace(")","");                 
                  break;
                  }
                }
          }

          this.MIPS477List =  result.form[0].Macra.groups[0][Globalconstants.MIPS477ListKey].groupAttribute;                   
          this.MIPS477List.sort(function (a, b) {
            return a.groupAttributeOrder - b.groupAttributeOrder;
          });
          
          
         // this.result = { "caseid": sessionStorage.getItem('caserecid'), "casetype":"Anesthesia", "providerNPI":"123456", "providerFirstName":"Mike", "providerLastName":"John", "phone":"1234456", "email":"mike.john@shcr.com",	 "patientDOS":"2020-01-01", "facilityName":"ADVANCEDSRGYCTROCONNOR", "facilityId":"2345", "gemnemonic":"",	"patientDemographics":{"mrn":"ANH83023",	"patientName":{"patientFirstName":"ANHANH","patientMiddleName":"j",	"patientLastName":"VUONG"},"patientDOB":"07-14-1957",	"gender":"Male" },	"anesthesiaType":"Other","asaLevel":"1","emergency":"1","postInductionCancellationFlag":"false"};
          this.apiService.getCaserecPatientDemographicDetails(sessionStorage.getItem('caserecid')).subscribe(
            result => { 
              //console.log("Caserec:",result);
                        if(result != undefined)
                        {
                        //var iAnesType =  JSON.parse(sessionStorage.getItem('AnesTypeDocList'));
                        let iAnesTypedata = this.AnesTypeDocList.find(ob => ob['groupAttributeSubItem'] === result.anesthesiaType);                        
                        if(iAnesTypedata && iAnesTypedata !== undefined)
                        {    
                          this.detailform.get('MAC701').setValue(iAnesTypedata.groupAttributeSubItemId);
                          this.detailform.get('MAC701').disable();
                          if(iAnesTypedata.groupAttributeSubItemId ==='MAC701D' || iAnesTypedata.groupAttributeSubItemId ==='MAC701E')
                          {
                            this.detailform.get('MAC035').setValue('1');
                            this.onChangeMIPS424NA(true);                            
                          }
                          
                        } 
                
                        //var iASA =  JSON.parse(sessionStorage.getItem('ASAStatus'));                        
                        let iASAdata = this.ASAStatus.find(ob => ob['groupAttributeSubItem'] === result.asaLevel);
                        if(iASAdata && iASAdata !== undefined)
                        {    
                          this.detailform.get('MAC702').setValue(iASAdata.groupAttributeSubItemId);
                          this.detailform.get('MAC702').disable();
                        }                 

                        // if emergency values comes as empty or false then default to 0
                        if(result.emergency == 'true' || result.emergency == '1')
                        {
                           //console.log(JSON.parse(this.result.emergency));
                           this.detailform.get('MAC703').setValue('1');
                           this.detailform.get('MAC703').disable();
                           this.detailform.get('MAC017').setValue('1');
                           this.detailform.get('MAC223').setValue('1');
                           this.onChangeMIPS404NA(true);
                           this.onChangeMIPS477NA(true);
                           
                        } else{
                           this.detailform.get('MAC703').setValue('0');
                        }
                      } 
                      this.BindDataforAge('I');
                      this.BindDatafromDB();                      
            });                    
        },
            error => {console.error(error); this.isErrorAlertAPICall = true; this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},
        );    
   }
 
   BindDataforAge(call:string)
   {
       this.apiService.getCaserecPatientDemographicDetails(sessionStorage.getItem('caserecid')).subscribe(
        result => {           
                   
                  if(result != undefined)
                  {
                    
                    //console.log(concatenatedDate);
                    const dateTo = new Date(formatDate(result.patientDOS,  'MM/dd/yyyy', 'en_US'));
                    let dateToSplit = result.patientDemographics.patientDOB.split("-");
                    let concatenatedDate = dateToSplit[0] + '/' + dateToSplit[1] + '/'+ dateToSplit[2];
                    const dateFrom = new Date(formatDate(concatenatedDate,  'MM/dd/yyyy', 'en_US'));
                  
                     if(dateTo.getTime() > dateFrom.getTime()) 
                     {
                        this.AgeInYear = (((Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate()) - Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate()) ) /(1000 * 60 * 60 * 24))/365);
                        //this.AgeInYear =  Math.floor(((Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate()) - Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate()) ) /(1000 * 60 * 60 * 24))/365);                            
                        //console.log(this.AgeInYear);
                        if(this.AgeInYear<=3)
                        {
                          this.AgeLoadMeasures('MAC709A',call);                          
                        }
                        else if(this.AgeInYear>3 && this.AgeInYear <=17)
                        {
                          this.AgeLoadMeasures('MAC709B',call);
                        }
                        else if(this.AgeInYear>17)
                        {
                          this.AgeLoadMeasures('MAC709C',call);
                        }
                     }
                    }     
        });

   }

   BindDatafromDB()
   {
    // Bind Saved values to UI.    
    //default all other section values to Not Applicable.
    this.detailform.get('MAC700').setValue('1');  
    this.detailform.get('MAC706').setValue('MAC706D');
    this.detailform.get('MAC707').setValue('MAC707C');
   
    if(sessionStorage.getItem('caserecid'))
    {
    this.apiService.getCaseDetailsByID(sessionStorage.getItem('caserecid')).subscribe(
      result => {         
               //console.log(result) ;              
               
              
               if(result.status == enumcaseStatus.Incomplete){                
                
                 for (let i = 0; i < result.attributes.length; i++) {            
                  
                    if(sessionStorage.getItem('AnesTypeDocList')){
                      var AnesType =  JSON.parse(sessionStorage.getItem('AnesTypeDocList')); 
                    //  console.log(AnesType) ;                     
                      let AnesTypedata = AnesType.find(ob => ob['groupAttributeSubItemId'] === result.attributes[i].attributeId);                        
                     // console.log(AnesTypedata);
                      if(AnesTypedata){        
                        this.detailform.get('MAC701').setValue(result.attributes[i].attributeId);
                      }
                    } 
                        
                       
                        if(sessionStorage.getItem('ASAStatus')){
                          var ASA =  JSON.parse(sessionStorage.getItem('ASAStatus'));                        
                          let ASAdata = ASA.find(ob => ob['groupAttributeSubItemId'] === result.attributes[i].attributeId);                        
                          if(ASAdata) {    
                            this.detailform.get('MAC702').setValue(result.attributes[i].attributeId);
                          }
                        }
                       
                                                
                        if(sessionStorage.getItem('Transferofcare')){
                          var Transferofcare =  JSON.parse(sessionStorage.getItem('Transferofcare'));                        
                          let Transferofcaredata = Transferofcare.find(ob => ob['groupAttributeSubItemId'] === result.attributes[i].attributeId);                        
                          if(Transferofcaredata)
                          {                          
                            this.detailform.get('MAC706').setValue(result.attributes[i].attributeId);
                          }
                        }
                       
                        if(sessionStorage.getItem('HandOffProtocol')){
                          var HandOffProtocol =  JSON.parse(sessionStorage.getItem('HandOffProtocol'));                        
                          let HandOffProtocoldata = HandOffProtocol.find(ob => ob['groupAttributeSubItemId'] === result.attributes[i].attributeId);                        
                          if(HandOffProtocoldata)
                          {                          
                            this.detailform.get('MAC707').setValue(result.attributes[i].attributeId);
                          }
                        }
                       
                  
                  if(result.attributes[i].attributeId =="MAC700")
                      {
                          this.detailform.get('MAC700').setValue(result.attributes[i].attributeValue);
                          if(result.attributes[i].attributeValue == "0")
                          {                      
                              this.isDisplay=  true ;
                              this.isANESQualitySectionDisplay=true;
                              this.isSignatureDisplay = true;
                          }
                      }                   
                      
                      else if(result.attributes[i].attributeId =="MAC703")
                      {                    
                          this.detailform.get('MAC703').setValue(result.attributes[i].attributeValue);                   
                      }
                      else if(result.attributes[i].attributeId =="MAC216")
                      {                    
                          this.detailform.get('MAC216').setValue(result.attributes[i].attributeValue);                   
                      }
                  else if(result.attributes[i].attributeId =="MAC217")
                            {                    
                                this.detailform.get('MAC217').setValue(result.attributes[i].attributeValue);                   
                                this.detailform.get('MAC216').reset();
                            }
                    else if(result.attributes[i].attributeId =="MAC218")
                            {                    
                                this.detailform.get('MAC218').setValue(result.attributes[i].attributeValue); 
                                this.detailform.get('MAC216').reset();                  
                            }
                    else if(result.attributes[i].attributeId =="MAC219")
                            {                    
                                this.detailform.get('MAC219').setValue(result.attributes[i].attributeValue);                  
                                this.detailform.get('MAC216').reset();
                                
                            }
                    else if(result.attributes[i].attributeId =="MAC201")
                            {                    
                                this.detailform.get('MAC201').setValue(result.attributes[i].attributeValue);                   
                                if(result.attributes[i].attributeValue=="0")
                                {
                               // this.detailform.get('MAC014').disable();
                                this.detailform.get('MAC015').disable();
                                this.detailform.get('MAC015A').disable();
                                this.MAC015Disabled = true;
                                this.MAC015ADisabled = true;
                                }
                            }                   
                    else if(result.attributes[i].attributeId =="MAC014")
                            {                    
                                this.detailform.get('MAC014').setValue(result.attributes[i].attributeValue); 
                                this.onChangeMIPS404NA(false);   
                                this.detailform.get('MAC017').reset();

                                if(result.attributes[i].attributeValue=="0")
                                {      
                                this.detailform.get('MAC201').disable();                
                                this.detailform.get('MAC015').disable();
                                this.detailform.get('MAC015A').disable();
                                this.detailform.get('MAC015B').disable();
                                this.MAC015Disabled = true;
                                this.MAC015ADisabled = true;
                                this.MAC015BDisabled = true;
                                }
                               
                            }
                    else if(result.attributes[i].attributeId =="MAC015")
                            {                    
                                this.detailform.get('MAC015').setValue(result.attributes[i].attributeValue);                   
                                this.onChangeMIPS404NA(false);   
                                this.detailform.get('MAC017').reset();

                                if(result.attributes[i].attributeValue=="0")
                                {                                            
                                  this.detailform.get('MAC015A').disable();
                                  this.detailform.get('MAC015B').disable();
                                  this.MAC015ADisabled = true;
                                  this.MAC015BDisabled = true;
                                }
                            }
                            else if(result.attributes[i].attributeId =="MAC015A")
                            {   
                                this.onChangeMIPS404NA(false);   
                                this.detailform.get('MAC017').reset();                 
                                this.detailform.get('MAC015A').setValue(result.attributes[i].attributeValue);                   
                            }
                    else if(result.attributes[i].attributeId =="MAC015B")
                            {  
                                this.onChangeMIPS404NA(false);   
                                this.detailform.get('MAC017').reset();                  
                                this.detailform.get('MAC015B').setValue(result.attributes[i].attributeValue);                   
                            }
                    else if(result.attributes[i].attributeId =="MAC022")
                            {                    
                                this.detailform.get('MAC022').setValue(result.attributes[i].attributeValue);                   

                                if (result.attributes[i].attributeValue=="0")
                                {
                                this.detailform.get('MAC022A').disable();
                                this.detailform.get('MAC022B').disable();
                                this.detailform.get('MAC022C').disable();
                                this.MAC022ADisabled = true;
                                this.MAC022BDisabled = true;
                                this.MAC022CDisabled = true;
                                }
                            }                    
                    else if(result.attributes[i].attributeId =="MAC022A")
                            {                    
                                this.detailform.get('MAC022A').setValue(result.attributes[i].attributeValue);                   
                            }
                    else if(result.attributes[i].attributeId =="MAC022B")
                            {                    
                                this.detailform.get('MAC022B').setValue(result.attributes[i].attributeValue);                   
                            }
                            else if(result.attributes[i].attributeId =="MAC022C")
                            {                    
                                this.detailform.get('MAC022C').setValue(result.attributes[i].attributeValue);                   
                            }
                    else if(result.attributes[i].attributeId =="MAC032")
                            {                    
                              this.detailform.get('MAC032').setValue(result.attributes[i].attributeValue);  
                             // console.log(result.attributes[i].attributeValue);           
                              this.onChangeMIPS424NA(false);
                              this.detailform.get('MAC035').reset();   
                              if(result.attributes[i].attributeValue=="0")
                              {
                                this.detailform.get('MAC032A').disable();
                                this.detailform.get('MAC032B').disable();
                                this.detailform.get('MAC032C').disable();
                                this.MAC032ADisabled = true;
                                this.MAC032BDisabled = true;
                                this.MAC032CDisabled = true;
                              }
                             

                            }
                    else if(result.attributes[i].attributeId =="MAC032A")
                            {                    
                                this.detailform.get('MAC032A').setValue(result.attributes[i].attributeValue);                   
                            }
                    else if(result.attributes[i].attributeId =="MAC032B")
                            {                    
                                this.detailform.get('MAC032B').setValue(result.attributes[i].attributeValue);                   
                            }
                    else if(result.attributes[i].attributeId =="MAC032C")
                            {                    
                                this.detailform.get('MAC032C').setValue(result.attributes[i].attributeValue);                   
                            }
                    else if(result.attributes[i].attributeId =="MAC221")
                            {                    
                                this.detailform.get('MAC221').setValue(result.attributes[i].attributeValue);                 
                                this.detailform.get('MAC223').reset();
                                this.onChangeMIPS477NA(false);
                                
                                if (result.attributes[i].attributeValue =="1")
                                {
                                  this.detailform.get('MAC222').disable();
                                  this.detailform.get('MAC222A').disable();
                                  this.detailform.get('MAC222B').disable();
                                  this.MAC222Disabled = true;
                                  this.MAC222ADisabled = true;
                                  this.MAC222BDisabled = true;
                                }  

                                if(this.detailform.get('MAC222').value =="0") 
                                {
                                  this.detailform.get('MAC222').enable();
                                  this.detailform.get('MAC222A').disable();
                                  this.detailform.get('MAC222B').disable();
                                  this.MAC222Disabled = false;
                                  this.MAC222ADisabled = true;
                                  this.MAC222BDisabled = true;
                                }
                                                    
                            }
                    else if(result.attributes[i].attributeId =="MAC222")
                            {  
                              this.detailform.get('MAC223').reset();
                                this.onChangeMIPS477NA(false);                  
                                this.detailform.get('MAC222').setValue(result.attributes[i].attributeValue);  
                                if(result.attributes[i].attributeValue == "0"){
                                  this.detailform.get('MAC222A').disable();
                                  this.detailform.get('MAC222B').disable();
                                  this.MAC222ADisabled = true;
                                  this.MAC222BDisabled = true;
                                }                 
                            }
                  else if(result.attributes[i].attributeId =="MAC222A")
                            {   
                              this.detailform.get('MAC223').reset();
                                this.onChangeMIPS477NA(false);                 
                                this.detailform.get('MAC222A').setValue(result.attributes[i].attributeValue);                   
                            }
                    else if(result.attributes[i].attributeId =="MAC222B")
                    {  
                        this.detailform.get('MAC223').reset();
                        this.onChangeMIPS477NA(false);                  
                        this.detailform.get('MAC222B').setValue(result.attributes[i].attributeValue);                   
                    }
                    else if(result.attributes[i].attributeId =="MAC224")
                            {                    
                                this.detailform.get('MAC224').setValue(result.attributes[i].attributeValue);                   
                            }
                    else if(result.attributes[i].attributeId =="MAC232")
                            {                    
                                this.detailform.get('MAC232').setValue(result.attributes[i].attributeValue);
                                this.onChangeMIPS463NA(false);                 
                                this.detailform.get('MAC240').reset();
                                if(result.attributes[i].attributeValue=="0")
                                {
                                  this.detailform.get('MAC235').disable();
                                  this.detailform.get('MAC237').disable();
                                  this.detailform.get('MAC238').disable();
                                  this.detailform.get('MAC239').disable();
                                  this.MAC235Disabled =  true;
                                  this.MAC237Disabled =  true;
                                  this.MAC238Disabled =  true;
                                  this.MAC239Disabled =  true;
                                }  
                                
                            }

                    else if(result.attributes[i].attributeId =="MAC235")
                            {                    
                                this.detailform.get('MAC235').setValue(result.attributes[i].attributeValue);                   
                                this.onChangeMIPS463NA(false);                 
                                this.detailform.get('MAC240').reset();
                                if (result.attributes[i].attributeValue=="0")
                                {
                                this.detailform.get('MAC237').disable();
                                this.detailform.get('MAC238').disable();
                                this.detailform.get('MAC239').disable();
                                this.MAC237Disabled =  true;
                                  this.MAC238Disabled =  true;
                                  this.MAC239Disabled =  true;
                                }
                                //this.onChangeMIPS463NA(false);
                            }
                    else if(result.attributes[i].attributeId =="MAC237")
                            {                    
                                this.detailform.get('MAC237').setValue(result.attributes[i].attributeValue);                   
                                //this.onChangeMIPS463NA(false);
                                this.onChangeMIPS463NA(false);                 
                                this.detailform.get('MAC240').reset();
                            }
                    else if(result.attributes[i].attributeId =="MAC238")
                            {                    
                                this.detailform.get('MAC238').setValue(result.attributes[i].attributeValue);                   
                                //this.onChangeMIPS463NA(false);
                                this.onChangeMIPS463NA(false);                 
                                this.detailform.get('MAC240').reset();
                            }
                    else if(result.attributes[i].attributeId =="MAC239")
                            {                    
                                this.detailform.get('MAC239').setValue(result.attributes[i].attributeValue);                   
                                //this.onChangeMIPS463NA(false);
                                this.onChangeMIPS463NA(false);                 
                                this.detailform.get('MAC240').reset();
                            }
                    else if(result.attributes[i].attributeId =="MAC226")
                            { 
                              this.detailform.get('MAC233').reset();
                              this.onChangeMIPS430NA(false);                   
                                this.detailform.get('MAC226').setValue(result.attributes[i].attributeValue);                   
                                if (result.attributes[i].attributeValue=="0")
                                {
                                this.detailform.get('MAC227').disable();
                                this.detailform.get('MAC228').disable();
                                this.detailform.get('MAC229').disable();
                                this.detailform.get('MAC231').disable();
                                this.MAC227Disabled = true;
                                this.MAC228Disabled = true;
                                this.MAC229Disabled = true;
                                this.MAC231Disabled = true;
                                }
                            }
                    else if(result.attributes[i].attributeId =="MAC227")
                            { 
                              this.detailform.get('MAC233').reset();
                              this.onChangeMIPS430NA(false);                           
                                this.detailform.get('MAC227').setValue(result.attributes[i].attributeValue);                   
                                if (result.attributes[i].attributeValue=="0")
                                {                      
                                this.detailform.get('MAC228').disable();
                                this.detailform.get('MAC229').disable();
                                this.detailform.get('MAC231').disable();
                                this.MAC228Disabled = true;
                                this.MAC229Disabled = true;
                                this.MAC231Disabled = true;
                                }
                            }
                    else if(result.attributes[i].attributeId =="MAC228")
                            {   
                              this.detailform.get('MAC233').reset();
                              this.onChangeMIPS430NA(false);                         
                                this.detailform.get('MAC228').setValue(result.attributes[i].attributeValue);                   
                            }
                    else if(result.attributes[i].attributeId =="MAC229")
                            {  
                              this.detailform.get('MAC233').reset();
                              this.onChangeMIPS430NA(false);                          
                                this.detailform.get('MAC229').setValue(result.attributes[i].attributeValue);                   
                            }
                    else if(result.attributes[i].attributeId =="MAC231")
                            {  
                              this.detailform.get('MAC233').reset();
                              this.onChangeMIPS430NA(false);                          
                                this.detailform.get('MAC231').setValue(result.attributes[i].attributeValue);                   
                            }
                    else if(result.attributes[i].attributeId =="MAC704")
                            {                    
                                this.detailform.get('MAC704').setValue(result.attributes[i].attributeValue);                   
                            }                    
                     
                            else if(result.attributes[i].attributeId =="MAC033")
                            {                    
                                this.name  = result.attributes[i].attributeValue;                   
                            } 
                            else if(result.attributes[i].attributeId =="MAC301")
                            {  
                              this.detailform.get('MAC312').reset();       
                              this.onChangeMIPSAQINA(false);                  
                              this.detailform.get('MAC301').setValue(result.attributes[i].attributeValue);              
                              this.detailform.get('MAC304').disable();
                              this.detailform.get('MAC305').disable();
                              this.detailform.get('MAC305A').disable();
                              this.detailform.get('MAC305B').disable();
                              this.MAC304Disabled = true;
                              this.MAC305Disabled = true;
                              this.MAC305ADisabled = true;
                              this.MAC305BDisabled = true;
                            } 
                            else if(result.attributes[i].attributeId =="MAC302")
                            { 
                              this.detailform.get('MAC312').reset();       
                              this.onChangeMIPSAQINA(false);                                  
                              this.detailform.get('MAC302').setValue(result.attributes[i].attributeValue);              
                              this.detailform.get('MAC304').disable();
                              this.detailform.get('MAC305').disable();
                              this.detailform.get('MAC305A').disable();
                              this.detailform.get('MAC305B').disable();
                              this.MAC304Disabled = true;
                              this.MAC305Disabled = true;
                              this.MAC305ADisabled = true;
                              this.MAC305BDisabled = true;
                            } 
                            else if(result.attributes[i].attributeId =="MAC303")
                            {  
                              this.detailform.get('MAC312').reset();       
                              this.onChangeMIPSAQINA(false);                                 
                              this.detailform.get('MAC303').setValue(result.attributes[i].attributeValue);  
                              this.detailform.get('MAC304').disable();
                              this.detailform.get('MAC305').disable();
                              this.detailform.get('MAC305A').disable();
                              this.detailform.get('MAC305B').disable() 
                              this.MAC304Disabled = true;
                              this.MAC305Disabled = true;
                              this.MAC305ADisabled = true;
                              this.MAC305BDisabled = true;           
                            } 
                            else if(result.attributes[i].attributeId =="MAC304")
                            { 
                              this.detailform.get('MAC312').reset();       
                              this.onChangeMIPSAQINA(false);                                  
                              this.detailform.get('MAC304').setValue(result.attributes[i].attributeValue);              
                              if(result.attributes[i].attributeValue==="0")
                              {            
                              this.detailform.get('MAC305').disable();
                              this.detailform.get('MAC305A').disable();
                              this.detailform.get('MAC305B').disable();                              
                              this.MAC305Disabled = true;
                              this.MAC305ADisabled = true;
                              this.MAC305BDisabled = true;
                              }

                            } 
                            else if(result.attributes[i].attributeId =="MAC305")
                            {
                              this.detailform.get('MAC312').reset();       
                              this.onChangeMIPSAQINA(false);                                   
                              this.detailform.get('MAC305').setValue(result.attributes[i].attributeValue);  
                              if(result.attributes[i].attributeValue==="0")
                              {                        
                              this.detailform.get('MAC305A').disable();
                              this.detailform.get('MAC305B').disable();                              
                              this.MAC305ADisabled = true;
                              this.MAC305BDisabled = true;
                              }
                            } 
                            else if(result.attributes[i].attributeId =="MAC305A")
                            { 
                              this.detailform.get('MAC312').reset();       
                              this.onChangeMIPSAQINA(false);                                  
                              this.detailform.get('MAC305A').setValue(result.attributes[i].attributeValue); 
                              if(result.attributes[i].attributeValue==="0")
                              {            
                                this.detailform.get('MAC305B').disable();                                 
                                this.MAC305BDisabled = true; 
                              }           
                            } 
                            else if(result.attributes[i].attributeId =="MAC305B")
                            { 
                              this.detailform.get('MAC312').reset();       
                              this.onChangeMIPSAQINA(false);                                  
                              this.detailform.get('MAC305B').setValue(result.attributes[i].attributeValue);                              
                            } 
                            else if(result.attributes[i].attributeId =="MAC306")
                            {                    
                              this.detailform.get('MAC306').setValue(result.attributes[i].attributeValue);  
                              if(result.attributes[i].attributeValue==="0")
                              {            
                              this.detailform.get('MAC307').disable();
                              this.detailform.get('MAC308').disable();
                              this.MAC307Disabled = true;
                              this.MAC308Disabled = true;
                              }
                            } 
                            else if(result.attributes[i].attributeId =="MAC307")
                            {                    
                              this.detailform.get('MAC307').setValue(result.attributes[i].attributeValue);              
                              if(result.attributes[i].attributeValue==="1")
                              {
                                this.detailform.get('MAC308').disable();
                                this.MAC308Disabled = true;
                              }
                            }
                            else if(result.attributes[i].attributeId =="MAC308")
                            {                    
                              this.detailform.get('MAC308').setValue(result.attributes[i].attributeValue);              
                            }
                            else if(result.attributes[i].attributeId =="MAC310")
                            {    
                              this.detailform.get('MAC314').reset();      
                              this.onChangeAnesTier(false);                
                              this.detailform.get('MAC310').setValue(result.attributes[i].attributeValue); 
                              if(result.attributes[i].attributeValue==="0")
                              {
                                this.detailform.get('MAC311').disable();
                                this.MAC311Disabled = true;
                              }             
                            }
                            else if(result.attributes[i].attributeId =="MAC311")
                            { 
                              this.detailform.get('MAC314').reset();      
                              this.onChangeAnesTier(false);                                  
                              this.detailform.get('MAC311').setValue(result.attributes[i].attributeValue);              
                            }
                            else if(result.attributes[i].attributeId =="MAC034")
                            {                    
                                this.date  = result.attributes[i].attributeValue;
                                this.detailform.get('BtnSign').disable();
                            } 
                            else if(result.attributes[i].attributeId =="MAC314")
                            {                    
                              this.detailform.get('MAC314').setValue(result.attributes[i].attributeValue);              
                              this.onChangeAnesTier(true);
                            }
                            else if(result.attributes[i].attributeId =="MAC313")
                            {                    
                              this.detailform.get('MAC313').setValue(result.attributes[i].attributeValue);              
                              this.onChangeQuantuam(true);
                            }
                            else if(result.attributes[i].attributeId =="MAC223")
                            {                    
                              this.detailform.get('MAC223').setValue(result.attributes[i].attributeValue);              
                              this.onChangeMIPS477NA(true);
                            }
                            else if(result.attributes[i].attributeId =="MAC240")
                            {                    
                              this.detailform.get('MAC240').setValue(result.attributes[i].attributeValue);              
                              this.onChangeMIPS463NA(true);
                            }
                            else if(result.attributes[i].attributeId =="MAC233")
                            {                    
                              this.detailform.get('MAC233').setValue(result.attributes[i].attributeValue);              
                              this.onChangeMIPS430NA(true);
                            }
                            else if(result.attributes[i].attributeId =="MAC312")
                            {                    
                              this.detailform.get('MAC312').setValue(result.attributes[i].attributeValue);              
                              this.onChangeMIPSAQINA(true);
                            }
                            else if(result.attributes[i].attributeId =="MAC017")
                            {                    
                              this.detailform.get('MAC017').setValue(result.attributes[i].attributeValue);              
                              this.onChangeMIPS404NA(true);
                            }
                            else if(result.attributes[i].attributeId =="MAC035")
                            {                    
                              this.detailform.get('MAC035').setValue(result.attributes[i].attributeValue);              
                              this.onChangeMIPS424NA(true);
                            }
                            else if(result.attributes[i].attributeId =="MAC024")
                            {                    
                              this.detailform.get('MAC024').setValue(result.attributes[i].attributeValue);              
                              this.onChangeMIPS76NA(true);
                            }
                            else if(result.attributes[i].attributeId =="MAC709A")
                            {
                              this.detailform.get('MAC709A').reset();             
                              this.detailform.get('MAC709B').reset();             
                              this.detailform.get('MAC709C').reset();             
                              this.detailform.get('MAC709A').setValue(result.attributes[i].attributeValue);              
                              this.DBAge='MAC709A';
                              
                            }
                            else if(result.attributes[i].attributeId =="MAC709B")
                            { 
                              this.detailform.get('MAC709A').reset();             
                              this.detailform.get('MAC709B').reset();             
                              this.detailform.get('MAC709C').reset();                                
                              this.detailform.get('MAC709B').setValue(result.attributes[i].attributeValue); 
                              this.DBAge='MAC709B';             
                              
                            }
                            else if(result.attributes[i].attributeId =="MAC709C")
                            { 
                              this.detailform.get('MAC709A').reset();             
                              this.detailform.get('MAC709B').reset();             
                              this.detailform.get('MAC709C').reset();                                
                              this.detailform.get('MAC709C').setValue(result.attributes[i].attributeValue);              
                              this.DBAge='MAC709C';
                            }
                      }  
                      this.BindDataforAge('B');
                      
              }
              else{
                  if(result.status == enumcaseStatus.Complete){
                    this.parent.isDisabled = true;
                    this.parent.isInfoAlert = true;
                    this.parent.InfoMessage = Globalconstants.InfoMessage;  
                    this.parent.btnClose = true;                   
                    
                    this.parent.DisableAllTabs();
                    //scroll to top of screen
                    window.scroll({
                     top: 0,
                     left: 0,
                     behavior: "smooth"
                   });
                   
                  }

              }
              
    },
    error => {console.error(error); this.isErrorAlertAPICall = true;this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},        
    );
  }

   }
   
   ngAfterViewInit(){

   }

   ngOnInit(): void {
  }

  toggle() {
    this.show = !this.show;    
  }
  // Event called on MIPS 76 Not Applicable checkbox to disable and enable attributes
  onChangeMIPS76NA(isChecked: boolean){
    if(isChecked === true){
      this.MIPS76Disabled = true;
      this.MAC022ADisabled =  true;
      this.MAC022BDisabled =  true;
      this.MAC022CDisabled =  true;
      this.detailform.get('MAC022').disable();
      this.detailform.get('MAC022A').disable();
      this.detailform.get('MAC022B').disable();
      this.detailform.get('MAC022C').disable();
      this.detailform.get('MAC022').reset();
      this.detailform.get('MAC022A').reset();
      this.detailform.get('MAC022B').reset();
      this.detailform.get('MAC022C').reset();
      this.hasErrorsCentral = false;
    } else {
      this.MIPS76Disabled = false;
      this.MAC022ADisabled =  false;
      this.MAC022BDisabled =  false;
      this.MAC022CDisabled =  false;
      this.detailform.get('MAC022').enable();
      this.detailform.get('MAC022A').enable();
      this.detailform.get('MAC022B').enable();
      this.detailform.get('MAC022C').enable();
    }
  }
  
  onChangeMIPS424NA(isChecked: boolean){
    if(isChecked === true){
      this.MIPS242Disabled = true; 
      this.MAC032ADisabled = true;
      this.MAC032BDisabled = true;
      this.MAC032CDisabled = true;
        this.detailform.get('MAC032').disable();
        this.detailform.get('MAC032A').disable();
        this.detailform.get('MAC032B').disable();
        this.detailform.get('MAC032C').disable();
        this.detailform.get('MAC032').reset();
        this.detailform.get('MAC032A').reset();
        this.detailform.get('MAC032B').reset();
        this.detailform.get('MAC032C').reset();
        this.hasErrorAnesCase60 = false;
    } else {
      this.MAC032ADisabled = false;
      this.MAC032BDisabled = false;
      this.MAC032CDisabled = false;
      this.MIPS242Disabled = false; 
      this.detailform.get('MAC032').enable();
      this.detailform.get('MAC032A').enable();
      this.detailform.get('MAC032B').enable();
      this.detailform.get('MAC032C').enable();
    }
  }

  

  onChangeMIPS404NA(isChecked: boolean){
    if(isChecked === true){
      this.MIPS404Disabled = true;
      this.MAC015Disabled = true;
      this.MAC015ADisabled = true;
      this.MAC015BDisabled = true;
      this.detailform.get('MAC014').disable();
      this.detailform.get('MAC015').disable();
      this.detailform.get('MAC015A').disable();
      this.detailform.get('MAC015B').disable();
      this.detailform.get('MAC201').disable();
      this.detailform.get('MAC014').reset();
      this.detailform.get('MAC015').reset();
      this.detailform.get('MAC015A').reset();
      this.detailform.get('MAC015B').reset();
      this.detailform.get('MAC201').reset();
      this.hasErrorPtabstained = false;
      this.hasErrorPtabstainedDOS= false;
      this.hasErrorElectiveCase = false;
      this.hasErrorPtreceivedprior = false;
    }  else {
      this.MIPS404Disabled = false;
      this.detailform.get('MAC014').enable();
      this.detailform.get('MAC015').enable();
      this.detailform.get('MAC015A').enable();
      this.detailform.get('MAC015B').enable();
      this.detailform.get('MAC201').enable();
      this.MAC015Disabled = false;
      this.MAC015ADisabled = false;
      this.MAC015BDisabled = false;

    }

  }

  onChangeMIPSAQINA(isChecked: boolean){
    if(isChecked === true){
      this.AQIDisabled =  true;
      this.MAC304Disabled=true;
      this.MAC305Disabled=true;
      this.MAC305ADisabled=true;
      this.MAC305BDisabled=true;
      this.detailform.get('MAC301').disable();
      this.detailform.get('MAC302').disable();
      this.detailform.get('MAC303').disable();
      this.detailform.get('MAC304').disable();
      this.detailform.get('MAC305').disable();
      this.detailform.get('MAC305A').disable();
      this.detailform.get('MAC305B').disable();

      this.detailform.get('MAC301').reset();
      this.detailform.get('MAC302').reset();
      this.detailform.get('MAC303').reset();
      this.detailform.get('MAC304').reset();
      this.detailform.get('MAC305').reset();
      this.detailform.get('MAC305A').reset();
      this.detailform.get('MAC305B').reset();
      this.hasErrorAQI304 = false;
      this.hasErrorAQI = false;
      this.hasErrorAQIPtreceived = false;
      this.hasErrorAQIProcedure = false;
    }  else {
      this.AQIDisabled =  false;
      this.MAC304Disabled=false;
      this.MAC305Disabled=false;
      this.MAC305ADisabled=false;
      this.MAC305BDisabled=false;
      this.detailform.get('MAC301').enable();
      this.detailform.get('MAC302').enable();
      this.detailform.get('MAC303').enable();
      this.detailform.get('MAC304').enable();
      this.detailform.get('MAC305').enable();
      this.detailform.get('MAC305A').enable();
      this.detailform.get('MAC305B').enable();
      //this.hasErrorAQI304 = true;
    }
  }
  onChangeMIPS430NA(isChecked: boolean){
    if(isChecked === true){
      this.MIPS430Disabled = true;
      this.MAC227Disabled = true;
      this.MAC228Disabled = true;
      this.MAC229Disabled = true;
      this.MAC231Disabled = true;
      this.detailform.get('MAC226').disable();
      this.detailform.get('MAC227').disable();
      this.detailform.get('MAC228').disable();
      this.detailform.get('MAC229').disable();
      this.detailform.get('MAC231').disable();

      this.detailform.get('MAC226').reset();
      this.detailform.get('MAC227').reset();
      this.detailform.get('MAC228').reset();
      this.detailform.get('MAC229').reset();
      this.detailform.get('MAC231').reset();
      this.hasErrorPatientreceivedinhal = false;
      this.hasErrorPatientexhibits3= false;
    } else{
      this.MIPS430Disabled = false;
      this.detailform.get('MAC226').enable();
      this.detailform.get('MAC227').enable();
      this.detailform.get('MAC228').enable();
      this.detailform.get('MAC229').enable();
      this.detailform.get('MAC231').enable();
      this.MAC227Disabled = false;
      this.MAC228Disabled = false;
      this.MAC229Disabled = false;
      this.MAC231Disabled = false;
    }
  }

  onChangeMIPS463NA(isChecked: boolean){
    if(isChecked === true){
      this.MIPS463Disabled =  true;
      this.MAC235Disabled = true;
      this.MAC237Disabled = true;
      this.MAC238Disabled = true;
      this.MAC239Disabled = true;
      this.detailform.get('MAC232').disable();
      this.detailform.get('MAC235').disable();
      this.detailform.get('MAC237').disable();
      this.detailform.get('MAC238').disable();
      this.detailform.get('MAC239').disable();

      this.detailform.get('MAC232').reset();
      this.detailform.get('MAC235').reset();
      this.detailform.get('MAC237').reset();
      this.detailform.get('MAC238').reset();
      this.detailform.get('MAC239').reset();

      this.hasErrorPatientexhibits2 = false;
      this.hasErrorPatientexhibits2Dtl = false;
    } else{
      this.MIPS463Disabled =  false;
      this.detailform.get('MAC232').enable();
      this.detailform.get('MAC235').enable();
      this.detailform.get('MAC237').enable();
      this.detailform.get('MAC238').enable();
      this.detailform.get('MAC239').enable();
      this.MAC235Disabled = false;
      this.MAC237Disabled = false;
      this.MAC238Disabled = false;
      this.MAC239Disabled = false;
    }
  }
  onChangeMIPS477NA(isChecked: boolean){
    if(isChecked === true){
      this.MIPS477Disabled =  true;
      this.MAC222Disabled = true;
      this.MAC222ADisabled = true;
      this.MAC222BDisabled = true;
      this.detailform.get('MAC221').disable();
      this.detailform.get('MAC222').disable();
      this.detailform.get('MAC222A').disable();
      this.detailform.get('MAC222B').disable();

      this.detailform.get('MAC221').reset();
      this.detailform.get('MAC222').reset();
      this.detailform.get('MAC222A').reset();
      this.detailform.get('MAC222B').reset();
      this.hasErrorOpenLog = false;
      this.hasErrorNonemergentcaseYes = false;  
      this.hasErrorNonemergentcase = false;    
      
    } else{
      this.MIPS477Disabled =  false;
      this.detailform.get('MAC221').enable();
      this.detailform.get('MAC222').enable();
      this.detailform.get('MAC222A').enable();
      this.detailform.get('MAC222B').enable();
      this.MAC222Disabled = false;
      this.MAC222ADisabled = false;
      this.MAC222BDisabled = false;
    }
  }
  onChangeQuantuam(isChecked: boolean){
    if(isChecked === true){
      this.MAC307Disabled = true;
      this.MAC308Disabled = true;
      this.detailform.get('MAC306').disable();
      this.detailform.get('MAC307').disable();
      this.detailform.get('MAC308').disable();

      this.detailform.get('MAC306').reset();
      this.detailform.get('MAC307').reset();
      this.detailform.get('MAC308').reset();
      this.hasErrorsMAC307 = false;
      this.hasErrorsMAC306 = false;
      this.Quan31Disabled =true;
    } else{
      this.detailform.get('MAC306').enable();
      this.detailform.get('MAC307').enable();
      this.detailform.get('MAC308').enable();
      this.MAC307Disabled = false;
      this.MAC308Disabled = false;
      this.Quan31Disabled =false;
    }
  }

  onChangeAnesTier(isChecked: boolean){
    if(isChecked === true){
      this.AnesTierDisabled = true;
      this.MAC311Disabled = true;
      this.detailform.get('MAC310').disable();
      this.detailform.get('MAC311').disable();

      this.detailform.get('MAC310').reset();
      this.detailform.get('MAC311').reset();
      this.hasErrorsMAC310 = false;
      
    } else{
      this.detailform.get('MAC310').enable();
      this.detailform.get('MAC311').enable();
      this.AnesTierDisabled = false;
      this.MAC311Disabled = false;
    }
  }

 // Submit Registration Form
 onSubmit() : boolean {  
   try  
          {     
            this.isErrorAlertAPICall = false;
            this.ErrorMessageAPICall = ""; 

     this.isFormSubmitted = true; 
     if (!this.detailform.valid) {
      if(this.detailform.controls["MAC216"].errors && this.detailform.controls["MAC217"].errors && this.detailform.controls["MAC218"].errors && this.detailform.controls["MAC219"].errors ){
        this.hasErrors = true;
       }
       if(this.detailform.controls["MAC301"].errors && this.detailform.controls["MAC302"].errors && this.detailform.controls["MAC303"].errors && this.detailform.controls["MAC304"].errors ){
        this.hasErrorAQI304 = true;
       }
       
       if((this.detailform.get('MAC307').value =="") && (this.detailform.get('MAC306').value)) {
         this.hasErrorsMAC306 = true;
      }
      if((this.detailform.get('MAC307').value == "0") && (this.detailform.get('MAC308').value == "")){
        this.hasErrorsMAC307 = true;
      }

       if(this.detailform.controls["MAC221"].errors){
        this.hasErrorNonemergentcase = true;
       }

       if((this.detailform.controls["MAC033"].errors))  {
        if(this.name) 
        {
          this.hasErrorsSignature = false;
          this.signatureError = false;
        }
        else{
        this.hasErrorsSignature = true;
        this.signatureError = true;
        }
       }
       
      //  if(this.detailform.controls["MAC311"].errors){
      //   this.hasErrorsMAC310 = true;
      //  } 
       

      //  if(this.detailform.controls["MAC704A"].errors && this.detailform.controls["MAC704B"].errors && this.detailform.controls["MAC704C"].errors && this.detailform.controls["MAC704D"].errors && this.detailform.controls["MAC704E"].errors ){
      //   this.hasErrorAdultPONVPatSpecific = true;
      //  } 
      if((this.detailform.get('MAC700').value ==="0")) // No validation apply if macra is NO 
      {
        return true;
      }
      else if((this.hasErrors== false) && (this.hasErrorsCentral == false) && 
       (this.hasErrorElectiveCase ==false) 
       && (this.hasErrorPtreceivedprior ==false) &&
      (this.hasErrorPtabstained ==false) &&
      (this.hasErrorPtabstainedDOS ==false) &&
      (this.hasErrorAnesCase60 ==false) &&
      (this.hasErrorPatientreceivedinhal==false) &&
      (this.hasErrorPatientexhibits3 ==false) &&
      (this.hasErrorPatientexhibits2 ==false) &&
      (this.hasErrorPatientexhibits2Dtl==false) &&
      (this.hasErrorNonemergentcase ==false) &&
      (this.hasErrorNonemergentcaseYes ==false) &&
      (this.hasErrorOpenLog == false) &&
      (this.hasErrorAQI ==false) &&
      (this.hasErrorsSignature == false)      
      )
      {                     
        //if (this.detailform.controls["MAC032"].errors || this.detailform.controls["MAC226"].errors || this.detailform.controls["MAC701"].errors || this.detailform.controls["MAC702"].errors || this.detailform.controls["MAC703"].errors || this.detailform.controls["MAC708"].errors || this.detailform.controls["MAC022"].errors || this.detailform.controls["MAC023"].errors || this.detailform.controls["MAC201"].errors || this.detailform.controls["MAC202"].errors || this.detailform.controls["MAC705"].errors || this.detailform.controls["MAC706"].errors || this.detailform.controls["MAC707"].errors)
        if (this.detailform.controls["MAC032"].errors ||
         this.detailform.controls["MAC226"].errors ||
          this.detailform.controls["MAC701"].errors || 
          this.detailform.controls["MAC702"].errors || 
          this.detailform.controls["MAC703"].errors ||
           this.detailform.controls["MAC022"].errors ||
            this.detailform.controls["MAC706"].errors || 
            this.detailform.controls["MAC707"].errors || 
            this.detailform.controls["MAC014"].errors || 
           // this.detailform.controls["MAC201"].errors ||
             this.detailform.controls['MAC306'].errors||
              this.detailform.controls['MAC310'].errors ||
             // this.detailform.controls['MAC311'].errors ||
             this.hasErrorsMAC310 === true ||
             this.hasErrorsMAC306 === true ||
             this.hasErrorsMAC307 === true ||
             this.detailform.controls['MAC232'].errors ||
             this.hasErrorAQI304 === true||
             this.hasErrorAQIProcedure === true||
             this.hasErrorAQIPtreceived === true ||
        this.detailform.controls["MAC304"].errors)
        { return false;}
        else{
        return true;
        }
      }
      
      else if((this.detailform.get('MAC700').value ==="0"))
      {
        //if (this.detailform.controls["MAC701"].errors || this.detailform.controls["MAC702"].errors || this.detailform.controls["MAC708"].errors )
        // if (this.detailform.controls["MAC701"].errors || this.detailform.controls["MAC702"].errors)
        // {
        //   return false;
        // }
        // else
        // {
          //Clear validation fields.
          this.hasErrors= false;
          this.hasErrorsCentral = false;
          this.hasErrorElectiveCase =false;
          this.hasErrorPtreceivedprior =false;
          this.hasErrorPtabstained =false;
          this.hasErrorPtabstainedDOS = false;
          this.hasErrorAnesCase60 =false;
          this.hasErrorPatientreceivedinhal=false;
          this.hasErrorPatientexhibits3 =false;
          this.hasErrorPatientexhibits2 =false;
          this.hasErrorPatientexhibits2Dtl=false;
          this.hasErrorNonemergentcaseYes =false;
          this.hasErrorOpenLog = false;
          this.hasErrorAQI=  false;
          //this.hasErrorAdultPONVPatSpecific=false;
          this.hasErrorsSignature = false  ;
          return true;
        //}

        
      }

      //this.detailform.markAllAsTouched();
     // this.scrollToFirstInvalidControl();     

      
       return false;
     } else {       
       return true;
     }

    }  
    catch(error)  
    { this.logger.log("Error raised in MACRA.component.ts - SubmitMacra :ERR::" + error.message);                           
      //this.isErrorAlertAPICall = true;
      //this.ErrorMessageAPICall = Globalconstants.ErrorMessageUI; 
    } 
  }  

  scrollIfFormHasErrors()
     {
    //await form.invalid;
    const firstElementWithError = document.querySelector(".text-danger");
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({ behavior: "smooth" });
    }
  }

  private scrollToFirstInvalidControl1() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "text-danger"
    );

    firstInvalidControl.focus(); //without smooth behavior
  }


  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      ".text-danger"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });

    fromEvent(window, "scroll")
      .pipe(
        debounceTime(500),
        take(1)
      )
      .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    //console.log("In Macra");
    const labelOffset = 50;
    //return window.scrollY - labelOffset;
   return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  ClickMAC703(event){ 
    //console.log(event.target.value);
    if(event.target.value === "1")
    {
      //if(!(this.detailform.get('MAC221').value || this.detailform.get('MAC222').value || this.detailform.get('MAC222A').value || this.detailform.get('MAC222B').value))
      //{
        this.detailform.get('MAC223').setValue('1');
        this.onChangeMIPS477NA(true);
      //}

      //if(!(this.detailform.get('MAC014').value ||  this.detailform.get('MAC015').value || this.detailform.get('MAC015A').value || this.detailform.get('MAC015B').value))
      //{
        this.detailform.get('MAC017').setValue('1');
        this.onChangeMIPS404NA(true);
      //}
      
    }
    else{
      if(!(this.detailform.get('MAC709A').value ==='1' || this.detailform.get('MAC709B').value ==='1' ))
      {
      this.detailform.get('MAC223').reset();
      this.onChangeMIPS477NA(false);
      this.detailform.get('MAC017').reset();      
      this.onChangeMIPS404NA(false);
      }

    }

  }

  onClickMsg(strStatus:string)
  {
      if(strStatus=="E")
      {
        this.isErrorAlertAPICall = false;
        this.ErrorMessageAPICall =  "";
      }

  }

  onChangeAnesType()
  {
    if(this.detailform.get('MAC701').value ==='MAC701D' || this.detailform.get('MAC701').value ==='MAC701E')
    {
        this.detailform.get('MAC035').setValue('1');
        this.onChangeMIPS424NA(true);                            
    }
    else{
      this.detailform.get('MAC035').reset();
      this.onChangeMIPS424NA(false);  
    }
                          
    //console.log(this.detailform.get('MAC701').value);
  }
   //monthDiff():number {
    // const dateTo = new Date('7/13/2010');
    // const dateFrom = new Date('01/01/2010');
    // return Math.floor((Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate()) - Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate()) ) /(1000 * 60 * 60 * 24));

    // // return dateTo.getMonth() - dateFrom.getMonth() + 
    //   (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))

    //let currentDate = new Date();
    //dateSent = new Date(dateSent);

    //return Math.floor((Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate()) - Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate()) ) /(1000 * 60 * 60 * 24));
   //}
   
}
