<div class="container sticky-top">
    <div class="row">
        <div class="col-md-12">
             <h5>&nbsp;</h5> 
             <div class="alert alert-success alert-dismissible" *ngIf="isSuccessAlert">
                <a href="javascript:void(0);" class="close" (click)="onClickMsg('S')"  aria-label="close">&times;</a>
                <strong>Success! </strong> {{SuccessMessage}}
              </div>
        </div>
        <div class="col-md-12">
            <div class="alert alert-danger alert-dismissible" *ngIf="isErrorAlert">
                <a href="javascript:void(0);" class="close" (click)="onClickMsg('A')" aria-label="close">&times;</a>
                <strong>Failure! </strong> {{ErrorMessage}}
            </div>
        </div>
        <div class="col-md-12">
            <div class="alert alert-info alert-dismissible" *ngIf="isInfoAlert">
                <a href="javascript:void(0);" class="close" (click)="onClickMsg('I')" aria-label="close">&times;</a>
                <strong>Notification! </strong> {{InfoMessage}}
            </div>
        </div>
        <div class="col-md-12">
                <div class="alert alert-danger alert-dismissible" *ngIf="isErrorAlertAPICall">
                    <a href="javascript:void(0);" class="close" (click)="onClickMsg('E')" aria-label="close">&times;</a>
                    <strong>Failure! </strong> {{ErrorMessageAPICall}}
                </div>
            </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card ">
                <div class="card-header"> 
                    <ul class="nav nav-tabs card-header-tabs justify-content-center"  id="myTab" role="tablist">
                        <li id="infoheader" class="nav-item">
                            <a class="nav-link"  id="info-tab"  (click)="selectedTab('info')" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="false">
                            INFO</a>
                        </li>
                        <li id="macraheader" class="nav-item"  >
                            <a class="nav-link active" id="macra-tab" data-toggle="tab" (click)="selectedTab('macra')" href="#macra" role="tab" aria-controls="macra"   aria-selected="true"  >MACRA</a>
                        </li>
                        <li id="caretoolheader" class="nav-item">
                            <a class="nav-link "  id="caretool-tab" (click)="selectedTab('caretool')" data-toggle="tab" href="#caretool"  role="tab" aria-controls="caretool"  aria-selected="false" (click)="CaretoolClick()">CARETOOL</a>
                        </li>
                        
                        
                    </ul>
                </div>
                <div class="pt-3">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">
                            <app-caseinfo></app-caseinfo>
                        </div>
                        <div class="tab-pane fade show active" id="macra" role="tabpanel" aria-labelledby="macra-tab">
                            <app-macra></app-macra>                 
                        </div>
                        <div class="tab-pane fade" id="caretool" role="tabpanel" aria-labelledby="caretool-tab">
                            <app-caretool></app-caretool>
                        </div>
                       
                       
                    </div>
                </div>
                
            </div>
            <div class="row">
                <ng-progress #progressBar (started)="onStarted()" (completed)="onCompleted()"></ng-progress>  
                <div class="col-12 mt-3 mb-2">
                        
                    <button type="button" class="btn btn-primary ml-2 float-right" (click)="ActiveTab === 'caretool' ? onClick('Complete') : onClick('Next')"  *ngIf="!isDisabled">
                        {{ ActiveTab === 'caretool' ? 'Submit' : 'Next' }}                            
                    </button>
                    <button type="button" class="btn btn-primary ml-2 float-right" (click)="onClick('Incomplete')"  *ngIf="!isDisabled">Save
                        
                    </button>
                    <button type="button" class="btn btn-danger ml-2 float-right" (click)="closeWindowtab()" *ngIf="!isDisabled">Cancel</button>
                    <button type="button" id="btnClose" class="btn btn-danger ml-2 float-right" *ngIf="btnClose" (click)="closeWindow()">
                        Close</button>
                    <!-- <button (click)="onClick()">Click</button> -->


                    
                </div>
            </div>
            
              
        </div>
        
    </div>
     
</div>
<!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
        <p style="color: white">Please Wait. </p>  
        </ngx-spinner>     -->
    